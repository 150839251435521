import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useGlobalState } from '../../../hooks/StateHook';
import { sendAmplitudeData } from '../../../amplitude/Amplitude';
import styles from './SendEmailModalCheckbox.module.css';

export default function SendEmailModalCheckbox({
  title, values, storeCheckedDataAs, checkboxType, pnr,
}) {
  const [checkboxObj, setcheckboxObj] = useState(JSON.parse(values)); // initialise ONE state object
  const [allGlobals, setGlobalState] = useGlobalState();

  // storing checked values to global variable - START
  /* istanbul ignore next */
  useEffect(() => {
    const checkedItems = [];
    const keyArr = Object.keys(checkboxObj);
    keyArr.forEach((key) => {
      if (checkboxObj[key] === true) { checkedItems.push(key); }
    });

    if (storeCheckedDataAs === 'countryFilterArr') setGlobalState({ countryFilterArr: checkedItems });
    else if (storeCheckedDataAs === 'travelTypeArr') setGlobalState({ travelTypeArr: checkedItems });
    else if (storeCheckedDataAs === 'emailChecks') setGlobalState({ emailChecks: checkedItems });
  }, [checkboxObj]);
  // storing checked values to global variable - END

  // reset checked values on reset btn press
  /* istanbul ignore next */
  useEffect(() => {
    if (allGlobals.clearFilter) {
      setcheckboxObj(JSON.parse(values));
    }
  }, [allGlobals.clearFilter]);

  const changeHandler = (e) => {
    const clone = JSON.parse(JSON.stringify(checkboxObj));
    clone[e.target.name] = !clone[e.target.name];
    setcheckboxObj(clone);
    setGlobalState({ clearFilter: false });
    // Amplitude start //
    const event = 'Select email address';
    // // eslint-disable-next-line
    // const xx = pnr; // temporary
    const props = {
      Description: 'User selects or deselects individual email address within "Email document" pop up',
      Source: 'ONEDDS',
      URL: window.location.hostname,
      HighValueInteraction: 'No',
      PNR: pnr,
      Tab: allGlobals.activeTab === 'trips' ? 'Recent Documents' : 'Search',
      EmailAddress: e.target.name,
    };
    sendAmplitudeData(event, props);
    // Amplitude end //
    // selectEmailAmplitudeEvent(e);
  };
  // country code to country name function
  const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });

  return (
    <div className={styles.checkBoxContainer}>
      <div className={styles.title}>{title}</div>
      {Object.keys(checkboxObj).map((data, index) => (
        <div className={styles.checkboxComponent} key={data}>
          <input
            type="checkbox"
            name={data}
            className={styles.Checkbox}
            checked={Object.values(checkboxObj)[index]}
            onChange={changeHandler}
          />
          {
            checkboxType === 'countryCode'
              ? (
                <label
                  htmlFor={data}
                  className={styles.label}
                >
                  {regionNamesInEnglish.of(data)}
                </label>
              )
              : <label htmlFor={data} className={styles.label}>{data}</label>
          }
        </div>
      ))}
      {/* <div className={styles.sectionLine} /> */}
    </div>
  );
}

// Checkbox.propTypes = {
//   title: PropTypes.string.isRequired,
//   values: PropTypes.string.isRequired,
//   storeCheckedDataAs: PropTypes.string.isRequired,
//   checkboxType: PropTypes.string.isRequired,
// };
