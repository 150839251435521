import React from 'react';
import LoadingCard from './LoadingCard';
import SidebarErrorCard from './SidebarErrorCard';
import NoInvoicesCard from './NoInvoicesCard';
import InvoiceCard from './InvoiceCard';
import ViewMoreCard from './ViewMoreCard';

export default function SidebarTripList({
  loading, trips, tripsToDisplay, activeBookingRef, activeInvoiceNumber, viewMoreTrips, loadTripsError, totalTrips,
}) {
  return (
    (() => {
      if (loading) {
        return (
          <div className="Scroll-Container">
            <LoadingCard />
          </div>
        );
      } if (loadTripsError) {
        return (
          <div className="Scroll-Container">
            <SidebarErrorCard />
          </div>
        );
      }
      if (trips.length === 0) {
        return (
          <div className="Scroll-Container">
            <NoInvoicesCard />
          </div>
        );
      }
      return (
        <div className="Scroll-Container">
          {trips.slice(0, tripsToDisplay).map((d) => (
            <>
              <InvoiceCard
                bookingRef={d.bookingRef}
                activeBookingRef={activeBookingRef}
                activeInvoiceNumber={activeInvoiceNumber}
                invoiceNumber={d.invoicenumber}
                date={d.date}
                name={d.name}
                trips={trips}
                tripsToDisplay={tripsToDisplay}
              />
              <div className="row">
                <div className="Card-Separation-Line" />
              </div>
            </>
          ))}
          <ViewMoreCard
            clickAction={() => viewMoreTrips()}
            total={totalTrips}
            viewing={tripsToDisplay}
          />
        </div>
      );
    })()
  );
}
