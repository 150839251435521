import React from 'react';
import PropTypes from 'prop-types';
import { writeGlobalState, readGlobalState } from '../../hooks/StateHook';
import styles from './ErrorBanner.module.css';

import error from '../../images/error.svg';
import { sendAmplitudeData } from '../../amplitude/Amplitude';

export default function ErrorBanner({ message }) {
  const [setGlobalState] = writeGlobalState();
  const [globalState] = readGlobalState();
  const closeFailure = () => {
    setGlobalState({ emailFailure: false });
    // Amplitude start //
    const event = 'Dismiss email result message';
    const props = {
      Description: 'User clicks "X" on message for whether document email was successful or not',
      Source: 'ONEDDS',
      URL: window.location.hostname,
      HighValueInteraction: 'No',
      Tab: globalState.activeTab === 'trips' ? 'Recent Documents' : 'Search',
      Message_Type: 'Failure',
    };
    sendAmplitudeData(event, props);
    // Amplitude end //
  };
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <img className={styles.errorIcon} src={error} alt="error" />
      </div>
      <div className={styles.text}>{message}</div>
      <div
        onClick={closeFailure}
        role="button"
        tabIndex={0}
        onKeyUp={closeFailure}
      >
        <img className={styles.closemark} src="https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/16x16/close_glyphs_gray05@1x.png" alt="failure" />
      </div>
    </div>
  );
}

ErrorBanner.propTypes = {
  message: PropTypes.string.isRequired,
};
