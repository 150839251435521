import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from './Modal';

export default function RemoveAssistantEmailModal({ closeModal, confirm, type }) {
  const { t } = useTranslation();
  return (
    <div>
      <Modal
        closeModal={closeModal}
        type={type}
        modalHeader={t('add_assistant_email_modal_header')}
        modalBody={type === 'assistant' ? t('remove_assistant_email_modal_body') : t('remove_traveler_email_modal_body')}
        buttonLabel={t('confirm')}
        confirm={confirm}
      />
    </div>
  );
}
