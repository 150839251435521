import React, { useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { sendAmplitudeData } from '../../amplitude/Amplitude';
import styles from './GlobeIcon.module.css';
import { useGlobalState } from '../../hooks/StateHook';
import globe from '../../images/style-icons-glyphs-icons-actions-global-default.png';

export default function GlobeIcon() {
  const languages = [
    { code: 'en', name: 'English', country_code: 'gb' },
    { code: 'fr', name: 'French', country_code: 'fr' },
    { code: 'de', name: 'German', countryCode: 'de' },
    { code: 'cz', name: 'Czech', countryCode: 'cz' },
    { code: 'da', name: 'Danish', countryCode: 'da' },
    { code: 'fi', name: 'Finnish', countryCode: 'fi' },
    { code: 'nl', name: 'Dutch', countryCode: 'nl' },
    { code: 'hu', name: 'Hungarian', countryCode: 'hu' },
    { code: 'it', name: 'Italian', countryCode: 'it' },
    { code: 'jp', name: 'Japanese', countryCode: 'jp' },
    { code: 'no', name: 'Norwegian', countryCode: 'no' },
    { code: 'pl', name: 'Polish', countryCode: 'pl' },
    { code: 'pt', name: 'Portuguese', countryCode: 'pt' },
    { code: 'es', name: 'Spanish', countryCode: 'es' },
    { code: 'se', name: 'Swedish', countryCode: 'se' },
  ];

  const [showMenu, setShowMenu] = useState(false);
  const [globalState, setGlobalState] = useGlobalState();
  const { t } = useTranslation();

  const viewLangEvent = () => {
    // Amplitude start //
    const event = 'View Languages';
    const props = {
      Description: 'User clicks on globe icon to view available languages',
      Source: 'ONEDDS',
      URL: window.location.hostname,
      HighValueInteraction: 'No',
    };
    sendAmplitudeData(event, props);
    // Amplitude end //
    // console.log(process.env);
  };

  const changeLangEvent = (language) => {
    // Amplitude start //
    const event = 'Change language';
    const props = {
      Description: 'User clicks on language to change viewed language',
      Source: 'ONEDDS',
      URL: window.location.hostname,
      HighValueInteraction: 'No',
      Language: language,
    };
    sendAmplitudeData(event, props);
    // Amplitude end //
    // console.log(process.env);
  };

  const isSelected = (language, name) => {
    i18next.changeLanguage(language);
    setShowMenu(false);
    changeLangEvent(name);
    setGlobalState({ langCode: language });
  };
  console.log(globalState.langCode, 'globalState');

  return (
    <div className="Globe">
      <div
        role="button"
        onClick={() => { setShowMenu(!showMenu); viewLangEvent(); }}
        onKeyDown={() => { setShowMenu(!showMenu); viewLangEvent(); }}
        tabIndex={0}
      >
        <img className="centered" src={globe} alt="Globe" />
        <div className="No-Mobile English-US">{t('language')}</div>
      </div>
      { showMenu
        ? (
          <div className={styles.container}>
            {languages.map(({ code, name, countryCode }) => (
              <li key={countryCode}>
                <div
                  role="button"
                  onClick={() => isSelected(code, name)}
                  onKeyDown={() => { i18next.changeLanguage(code); changeLangEvent(name); }}
                  tabIndex={0}
                >
                  {name}
                </div>
              </li>
            ))}
          </div>
        ) : ''}
    </div>
  );
}
