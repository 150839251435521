import React from 'react';
import { useTranslation } from 'react-i18next';
import invoice from '../../../images/style-icons-universal-invoice-invoice-active.png';
import styles from './Invoices.module.css';

export default function Invoices() {
  const { t } = useTranslation();
  return (
    <div className={styles.HeaderTab}>
      <div className={styles.LinkLine1}>
        <img className={styles.StyleIconsUniversalInvoiceinvoice_active} src={invoice} alt="Invoice" />
        <span className="Vertical-Center">{t('invoices')}</span>
      </div>
      {/* <div className="Line" /> */}
    </div>
  );
}
