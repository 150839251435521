import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './Country.module.css';
import { useGlobalState } from '../../../hooks/StateHook';

export default function Country({ values }) {

  const [list, setList] = useState([])
  const { t } = useTranslation();
  const [globalState, setGlobalState] = useGlobalState();
  const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });

  const checkHandler = (e) => {
    const countryCode = e.target.name
    let countryCodeSet = new Set(list)

    if (countryCodeSet.size && countryCodeSet.has(countryCode)) countryCodeSet.delete(countryCode)
    else countryCodeSet.add(countryCode)

    setList([...countryCodeSet])
    setGlobalState({
      countryFilterArr: [...countryCodeSet],
      clearFilter: false
    })
  }

  useEffect(() => { 
    setList([])
  }, [values])

  useEffect(() => { 
    if (globalState.clearFilter) setList([])
  }, [globalState.clearFilter])

  return (
    <div className={styles.countryDiv}>
      <div className={styles.title}>{t('country_of_billing')}</div>
      {values.map((data) => (
        <div className={styles.checkboxComponent} key={data}>
          <input
            type="checkbox"
            name={data}
            className={styles.Checkbox}
            checked={list.includes(data)}
            onChange={checkHandler}
          />

          <label
            htmlFor={data}
            className={styles.label}
          >
            {regionNamesInEnglish.of(data)}
          </label>
        </div>
      ))}
    </div>
  );
}

Country.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string).isRequired
};