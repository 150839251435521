import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import account from '../../images/style-icons-glyphs-icons-general-account-account-default.png';

export default function ExpandableNameList({
  names,
}) {
  const [expanded, setExpanded] = useState(false);
  const itemsLimit = 2;
  const expand = () => {
    setExpanded(true);
  };
  const collapse = () => {
    setExpanded(false);
  };
  const { t } = useTranslation();
  const displayNames = Array.isArray(names)
    ? Array.from(new Set(names))
    : [names];

  return (
    <>
      <div className="Traveler-Name">
        <img alt="Account" className="Style-Icons-Glyphs-Icons-General-Account-Account-Default" src={account} />
        <div className="Name">
          {
              expanded || displayNames.length <= itemsLimit
                ? displayNames.map((name) => (
                  <>
                    {name}
                    <br />
                  </>
                ))
                : displayNames.slice(0, itemsLimit).map((name) => (
                  <>
                    {name}
                    <br />
                  </>
                ))
            }
        </div>
      </div>
      {!expanded && displayNames.length > itemsLimit
        ? (
          <div role="button" tabIndex={0} className="Expand-Emails" onClick={expand} onKeyDown={expand}>
            +
            {displayNames.length - itemsLimit}
            {' '}
            {t('more')}
          </div>
        )
        : ''}
      {expanded && displayNames.length > itemsLimit
        ? <div role="button" tabIndex={0} className="Expand-Emails" onClick={collapse} onKeyDown={collapse}>{t('show_less')}</div>
        : ''}
    </>
  );
}

ExpandableNameList.propTypes = {
  // eslint-disable-next-line
  names: PropTypes.arrayOf(PropTypes.object).isRequired,
};
