/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ViewMore.module.css';
import { readGlobalState } from '../../hooks/StateHook';

export default function ViewMore({ viewMore }) {

    const [globalState] = readGlobalState();
    const { t } = useTranslation();

    useEffect(() => {
        if (!globalState.docs.length) {
            window.scrollTo(0,0)
        }
    }, [globalState.docs])
    return (
        <div
            className={styles.viewMoreTab}
            onClick={viewMore}
        > {t('view_more')}
        </div>
    )
}