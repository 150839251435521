/* eslint-disable prefer-regex-literals */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useGlobalState } from '../../../hooks/StateHook';
import styles from './Minmax.module.css';

export default function Minmax({ title, minLabel, maxLabel }) {
  const [allGlobals, setGlobalState] = useGlobalState();
  const { t } = useTranslation();
  const [minVal, setMinVal] = useState('');
  const [maxVal, setMaxVal] = useState('');
  const [errMsg, setErrMsg] = useState('');

  // data-type number check - regex
  const isNum = (data) => {
    // if (!data) false;
    if (data === '') return true;
    const reg = new RegExp('^[-]?[0-9]+[.]?[0-9]*$');
    const output = reg.test(data);
    return output;
  };

  // data-type number check - regex
  /* istanbul ignore next */
  const isNumber = (data) => {
    if (!data) {
      return false;
    }

    const reg = new RegExp('^[-]?[0-9]+[.]?[0-9]*$');
    const output = reg.test(data);
    return output;
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (maxVal && minVal) { // both min and max value exists
      if (Number(minVal) > Number(maxVal)) { // max value > min value, else error
        setErrMsg(t('filter_error_msg1'));
      } else if (isNumber(minVal) && isNumber(maxVal)) { // only numbers allowed
        const arr = [minVal, maxVal];
        setGlobalState({ amountMinMaxArr: arr }); // store in global variable
      }
    } else if (minVal || maxVal) { // at least one of min or man exists
      if (isNumber(minVal) || isNumber(maxVal)) { // type check - Number
        let arr = [];
        if (isNumber(minVal)) {
          if (Number(minVal) < 0 || Number(minVal) === 0) {
            arr = [minVal, '0'];
            setGlobalState({ amountMinMaxArr: arr }); // store in global variable
            setErrMsg('');
          } else { setErrMsg(t('filter_error_msg')); }
        } else if (isNumber(maxVal)) {
          if (Number(maxVal) > 0 || Number(maxVal) === 0) {
            arr = ['0', maxVal];
            setGlobalState({ amountMinMaxArr: arr }); // store in global variable
            setErrMsg('');
          } else {
            setErrMsg(t('filter_error_msg'));
          }
        }
      }
    } else if (allGlobals.amountMinMaxArr[0] !== '' || allGlobals.amountMinMaxArr[1] !== '') {
      setMinVal(allGlobals.amountMinMaxArr[0]);
      setMaxVal(allGlobals.amountMinMaxArr[1]);
    } else {
      const arr = ['', '']; // both are empty
      setGlobalState({ amountMinMaxArr: arr }); // ["",""] value stored in global var
    }
  }, [minVal, maxVal]);

  // reset data when clear filer btn press
  /* istanbul ignore next */
  useEffect(() => {
    if (allGlobals.clearFilter) {
      setMinVal('');
      setMaxVal('');
      setErrMsg('');
    }
  }, [allGlobals.clearFilter]);

  // executes every time new number/character entered
  const changeHandler = (e) => {
    if (isNum(e.target.value)) { // character entered- Number check
      if (e.target.name === 'minVal') {
        setMinVal(e.target.value);
        setGlobalState({ clearFilter: false });

        if (isNum(maxVal)) setErrMsg(''); // no error if value is a number
      } else if (e.target.name === 'maxVal') {
        setMaxVal(e.target.value);
        setGlobalState({ clearFilter: false });
        // setErrMsg('')
        if (isNum(minVal)) setErrMsg('');
      }
    } else if (e.target.name === 'minVal') { // character entered - not a number
      setMinVal(e.target.value);
      setErrMsg(t('filter_error_msg')); // invalid char error
    } else if (e.target.name === 'maxVal') {
      setMaxVal(e.target.value);
      setErrMsg(t('filter_error_msg'));
    }
  };

  return (
    <div>
      <div className={styles.title}>{title}</div>
      <div className={styles.flexbox}>
        <div className={styles.minEntry}>
          <label className={styles.minLabel} htmlFor="minVal">{minLabel}</label>
          <input className={styles.minValue} type="text" name="minVal" onChange={changeHandler} value={minVal} placeholder="0.00" />
          <div className={styles.minLine} />
        </div>
        <div className={styles.maxEntry}>
          <label className={styles.maxLabel} htmlFor="maxVal">{maxLabel}</label>
          <input className={styles.maxValue} type="text" name="maxVal" onChange={changeHandler} value={maxVal} placeholder="0.00" />
          <div className={styles.maxLine} />
        </div>
      </div>

      {errMsg !== '' && (<div className={styles.errorMsg}>{errMsg}</div>)}
      <div className={styles.sectionLine} />
    </div>
  );
}

Minmax.propTypes = {
  title: PropTypes.string.isRequired,
  minLabel: PropTypes.string.isRequired,
  maxLabel: PropTypes.string.isRequired,
};
