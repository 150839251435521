import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from './Modal';
import styles from './Modal.module.css';
import Loader from '../../common/Loader';
import LanguageDropdown from './LanguageDropdown';
import { requestAssistantEmail } from '../../../apis/OneDDSAPI';
import { useGlobalState } from '../../../hooks/StateHook';

export default function RequestAssistantEmailModal({ closeModal }) {
  const [requestingAssistant, setRequestingAssistant] = useState(false);
  const [values, setValues] = useState({});
  const [validEmailFormat, setValidEmailFormat] = useState(false);
  const [validDomain, setValidDomain] = useState(false);
  const [sameEmail, setSameEmail] = useState(false);
  const { t } = useTranslation();
  const [globalState] = useGlobalState();
  const [errorMessage, setErrorMessage] = useState('');

  const requestpayload = {
    legend: {
      target: 'es',
      action: 'email',
    },
    body: {
      assistantEmail: globalState.userEmail,
      bookingEmail: values.email,
      loggedUser: globalState.name,
      loggedUserEmail: globalState.userEmail,
      lang: globalState.lang,
    },
  };

  /* istanbul ignore next */
  const handleEmailValidate = (email) => {
    const emailRegex = /^.*@[^@][a-zA-Z0-9-]+\.[^.]{2,}(?:\.[^.]{2,})*$/;
    return emailRegex.test(String(email).trim().toLowerCase());
  };

  /* istanbul ignore next */
  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues(() => ({
      ...values,
      [name]: value,
    }));
  };

  /* istanbul ignore next */
  const handleRequestAssistantEmail = () => {
    if (validEmailFormat || sameEmail) {
      setRequestingAssistant(true);
      requestAssistantEmail(requestpayload)
        .then((data) => data.json())
        .then(() => {
          closeModal();
          setRequestingAssistant(true);
        })
        .catch((error) => {
          console.log(error, 'Error');
          closeModal();
          setRequestingAssistant(true);
        });
    }
  };

  /* istanbul ignore next */
  const handleDomainValidate = (email) => {
    if (email) {
      const isEmailValid = handleEmailValidate(email);
      setValidEmailFormat(isEmailValid);
      setValidDomain(true);
      const check = globalState.userEmail.trim() !== values.email.trim();
      setSameEmail(check);
      if (!isEmailValid) {
        setErrorMessage(t('invalid_email_error'));
      } else if (!check) {
        setErrorMessage(t('own_email_error'));
      } else if (isEmailValid && check) {
        const bookingEmailDomain = globalState.userEmail.toLowerCase().split('@')[1];
        const assistantEmailDomain = values.email && values.email.trim().toLowerCase().split('@')[1];
        const checkingDomain = assistantEmailDomain === bookingEmailDomain;
        setValidDomain(checkingDomain);
        setErrorMessage(t('domain_validation'));
      } else {
        setErrorMessage('');
        setValidDomain(false);
        setSameEmail(false);
      }
    } else {
      setValidDomain(false);
      setValidEmailFormat(false);
      setSameEmail(false);
      setErrorMessage('');
    }
  };

  /* istanbul ignore next */
  const handleValidateStyle = () => {
    let style;
    if ((!validEmailFormat || !sameEmail) && values.email !== undefined) {
      style = styles.inputContainerError;
    } else if (!validDomain && values.email !== undefined) {
      style = styles.inputContainerWarning;
    } else {
      style = styles.inputContainer;
    }
    return style;
  };

  /* istanbul ignore next */
  useEffect(() => {
    const delay = setTimeout(() => {
      handleDomainValidate(values.email);
    }, 500);

    return () => clearTimeout(delay);
  }, [values.email]);

  return (
    <div>
      <Modal
        closeModal={closeModal}
        modalHeader={t('request')}
      >
        <div className={handleValidateStyle()}>
          <input
            className={styles.inputText}
            id="text1"
            type="text"
            name="email"
            value={values.email}
            onChange={handleChange}
          />
          {!validDomain ? <div className={styles.errorTextWarning}>{errorMessage}</div> : ''}
          {!validEmailFormat ? <div className={styles.errorText}>{errorMessage}</div> : ''}
          {!sameEmail ? <div className={styles.errorText}>{errorMessage}</div> : ''}
        </div>
        <div className={styles.body}>
          {t('request_assistant_email_modal_body')}
          <span className={styles.lang}>
            <LanguageDropdown
              type="assistant"
            />
          </span>
        </div>
        <div className={styles.separator} />
        <div className={styles.button}>
          <button
            type="button"
            className={validEmailFormat && sameEmail ? styles.addButton : styles.disabledButton}
            disabled={!validEmailFormat || !sameEmail}
            onClick={handleRequestAssistantEmail}
            onKeyDown={handleRequestAssistantEmail}
            tabIndex={0}
          >
            {requestingAssistant ? <Loader /> : t('send')}
          </button>
        </div>
      </Modal>
    </div>
  );
}
