import React, { useEffect, useState } from 'react';
import styles from './Checkbox.module.css';

export default function Checkbox({ onCheck, defaultChecked }) {
  const [checked, setChecked] = useState(defaultChecked !== undefined ? defaultChecked : false);
  useEffect(() => setChecked(defaultChecked), [defaultChecked]);
  const check = () => {
    const event = {
      target: {
        checked: !checked,
      },
    };
    setChecked(!checked);
    console.log(`Checked: ${checked}`);
    onCheck(event);
  };

  return (
    <div className={checked ? styles.containerChecked : styles.container} role="button" tabIndex={0} onClick={(e) => check(e)} onKeyDown={(e) => check(e)}>
      <div className={styles.checkmark} />
    </div>
  );
}
