import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOktaAuth } from '@okta/okta-react';
import { getConfigForEnvironment } from '../../../config/env-config';
import { writeGlobalState } from '../../../hooks/StateHook';

import styles from './Home.module.css';

export default function Home() {
  const { oktaAuth } = useOktaAuth();
  const { t } = useTranslation();
  const [setGlobalState] = writeGlobalState();

  const logout = async () => {
    oktaAuth.tokenManager.clear();
    // await oktaAuth.signOut();
    window.close();
    setGlobalState({ userEmail: undefined });
    const href = getConfigForEnvironment().salesforce.url;
    const link = document.createElement('a');
    link.href = href;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <div
      onClick={logout}
      onKeyDown={logout}
      role="button"
      tabIndex={0}
      className="Header-Tab"
    >
      <div className={styles.text}>
        <img
          className={styles.StyleIconsUniversalInvoiceinvoice_active}
          alt="Home"
          src="https://cdn.amexgbt.com/brand19/icons/png/24x24/home_active@3x.png"
        />
        <span className={styles.label}>{t('home')}</span>
      </div>
    </div>
  );
}
