import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '../../../hooks/StateHook';
import styles from './FilterTopBar.module.css';

export default function FilterTopBar({ clearFiltersFunc }) {
// export default function FilterTopBar() {
  const [allGlobals, setGlobalState] = useGlobalState();
  const [filterCount, setFilterCount] = useState(0);
  const [fireAmplitudeEvent, setFireAmplitudeEvent] = useState(false);
  const { t } = useTranslation();

  // number of filters calculation function
  /* istanbul ignore next */
  const numOfFilters = (travelF, countryF, amountF, documentF) => {
    let count = 0;
    if (travelF && travelF.length !== 0) count += 1;
    if (countryF && countryF.length !== 0) count += 1;
    if (documentF && (documentF === 'Credit' || documentF === 'Debit')) count += 1;
    if (amountF && !(amountF[0] === '' && amountF[1] === '')) count += 1;
    return count;
  };

  // calculating number of filters applied
  /* istanbul ignore next */
  useEffect(() => {
    const count = numOfFilters(
      allGlobals.travelTypeArr,
      allGlobals.countryFilterArr,

      allGlobals.amountMinMaxArr,

      allGlobals.documentTypes,
    );
    setFilterCount(count);
  }, [allGlobals.travelTypeArr, allGlobals.countryFilterArr, allGlobals.amountMinMaxArr, allGlobals.documentTypes]);

  /* istanbul ignore next */
  useEffect(() => {
    console.log(`*****${allGlobals.clearFilter}`);
    if (allGlobals.clearFilter && fireAmplitudeEvent) {
      clearFiltersFunc(true);
      setFireAmplitudeEvent(false);
    } else if (allGlobals.clearFilter && !fireAmplitudeEvent) clearFiltersFunc(false);
  }, [allGlobals.clearFilter]);

  // clear filter functionality - resetting global variables
  const clickHandler = () => {
    setGlobalState({
      amountMinMaxArr: ['', ''],
      travelTypeArr: [],
      countryFilterArr: [],
      selectedDocuments: [],
      documentTypes: '',
      clearFilter: true,
      refineSearch: false,
    });
    setFireAmplitudeEvent(true);
  };

  return (
    <div>
      <div className={styles.sectionLine} />
      <div className={styles.flexBox}>
        <div className={styles.title}>{t('filter_results')}</div>
        <div
          className={styles.clearFilter}
          onClick={clickHandler}
          // onKeyDown={clickHandler}
          onKeyDown={() => { console.log('Clear filters'); }}
          role="button"
          tabIndex={0}
        >
          {t('clear_filters')}
        </div>
      </div>
      {filterCount !== 0 && (
        <div className={styles.filtersApplied}>
          {' '}
          {filterCount}
          {' '}
          {t('filters_applied')}
        </div>
      )}

    </div>
  );
}
