/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';

let warnTimeoutStorage;
let logoutTimeoutStorage;

export default function (ComposedClass) {
  return () => {
    const { oktaAuth } = useOktaAuth();
    const [warningTime, setWarningTime] = useState(1000 * 60 * 15);
    const [logoutTime, setLogoutTime] = useState(1000 * 60 * 20);
    const [refreshEvents, setRefreshEvents] = useState(['load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress']);

    const warn = () => {
      console.log('warn');
    };

    const logout = () => {
      console.log('signout triggered');
      oktaAuth.signOut().then((response) => {
        oktaAuth.tokenManager.clear();
        console.log('Okta signout finished');
      });
    };

    const resetTimeout = () => {
      if (warnTimeoutStorage) clearTimeout(warnTimeoutStorage);
      if (logoutTimeoutStorage) clearTimeout(logoutTimeoutStorage);
      warnTimeoutStorage = setTimeout(warn, warningTime);
      logoutTimeoutStorage = setTimeout(logout, logoutTime);
    };

    useEffect(() => {
      warnTimeoutStorage = setTimeout(warn, warningTime);
      logoutTimeoutStorage = setTimeout(logout, logoutTime);
      refreshEvents.forEach((event) => {
        window.addEventListener(event, resetTimeout);
      });
    }, []);

    return (
      <div>
        <ComposedClass />
      </div>
    );
  };
}
