import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './SearchElementTable.module.css';

export default function SearchElementTable() {
  const { t } = useTranslation();
  return (
    <table className={styles.searchTable}>
      <tr>
        <th>{t('element')}</th>
        <th>{t('example')}</th>
        <th>{t('element')}</th>
        <th>{t('example')}</th>
      </tr>
      <tr>
        <td>{t('doc_number')}</td>
        <td>7000701</td>
        <td>{t('total')}</td>
        <td>75.20</td>
      </tr>
      <tr>
        <td>{t('traveler_full_name')}</td>
        <td>TESTER/MARKUS</td>
        <td>{t('currency_code')}</td>
        <td>EUR</td>
      </tr>
      <tr>
        <td>{t('traveler_first_name')}</td>
        <td>MARKUS</td>
        <td>{t('gds')}</td>
        <td>RTYSG1</td>
      </tr>
      <tr>
        <td>{t('traveler_surname')}</td>
        <td>TESTER</td>
        <td>{t('rail')}</td>
        <td>70902415</td>
      </tr>
      <tr>
        <td>{t('customer_name')}</td>
        <td>AUSTRIA GMBH</td>
        <td>{t('short_card')}</td>
        <td>1109</td>
      </tr>
      <tr>
        <td>{t('customer_num1')}</td>
        <td>0150100000</td>
        <td>{t('reference_data')}</td>
        <td>196894</td>
      </tr>
      <tr>
        <td>{t('air/rail')}</td>
        <td>839591773</td>
        <td>{t('lcc')}</td>
        <td>K691BMP</td>
      </tr>
      <tr>
        <td>{t('cust_client')}</td>
        <td>UATD96</td>
        <td>{t('credit')}</td>
        <td>-7.50</td>
      </tr>
    </table>
  );
}
