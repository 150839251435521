import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ExportControls from './ExportControls';
import { sendAmplitudeData } from '../../amplitude/Amplitude';
import styles from './InvoiceDetails.module.css';
import { useGlobalState } from '../../hooks/StateHook';
import ExpandableNameList from './ExpandableNameList';
import MultipleUsers from '../topusers/MultipleUsers';

export default function InvoiceDetails({
  emails, hideExportControls, bookingRef, date, selectedDocuments, invoices, pnr, name,
}) {
  const { t } = useTranslation();
  const monthMap = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  const [globalState, setGlobalState] = useGlobalState();
  const [allSelected, setAllSelected] = useState(false);
  const [focusDownload, setFocusDownload] = useState(false);
  const [focusEmail, setFocusEmail] = useState(false);

  const exportControlFocus = () => {
    if (selectedDocuments.length) {
      const found = selectedDocuments.find((data) => data.pnr === pnr);
      const found2 = selectedDocuments[0].pnr === pnr;
      if (found) {
        setFocusDownload(true);
      } else setFocusDownload(false);
      if (found2) setFocusEmail(true);
      else setFocusEmail(false);
    } else {
      setFocusDownload(false);
      setFocusEmail(false);
    }
  };
  useEffect(() => {
    if (!globalState.selectedDocuments.length) setAllSelected(false);
    exportControlFocus();
  }, [globalState.selectedDocuments]);

  if (!emails) {
    return (
      <div className={styles.title}>
        <div className={styles.circle}>
          <h1>i</h1>
        </div>
        {t('no_invoices_found')}
        {/* <div className={styles.description}>
          <span className={styles.redirect_msg1}>{t('redirect_msg1')}</span>
          <a
            className={styles.opentextURL2}
            href="https://invoicing-amexgbt.opentext.com/amexgbt/page/login"
            target="_blank"
            rel="noreferrer"
          >
            {t('redirect_msg2')}
          </a>
          <span className={styles.redirect_msg3}>{t('redirect_msg3')}</span>
        </div> */}
      </div>
    );
  }

  let exportControlsClass = 'responsive-row';

  if (hideExportControls) {
    exportControlsClass = styles.hidden;
  }

  // const exportControlFocus = () => {
  //   if (selectedDocuments.length) {
  //     const found = selectedDocuments.find((data) => data.pnr === pnr);
  //     const found2 = selectedDocuments[0].pnr === pnr;
  //     if (found) setFocusDownload(true);
  //     else setFocusDownload(false);
  //     if (found2) setFocusEmail(true);
  //     else setFocusEmail(false);
  //   } else {
  //     setFocusDownload(false);
  //     setFocusEmail(false);
  //   }
  // };

  // useEffect(() => {
  //   if (!globalState.selectedDocuments.length) setAllSelected(false);
  //   exportControlFocus();
  // }, [globalState.selectedDocuments]);

  const selectDocAmplitudeEvent = () => {
    // Amplitude start //
    const event = 'Select document';
    const props = {
      Description: 'User selects or deselects individual invoice or "documents" option to select all PNR invoices',
      Source: 'ONEDDS',
      URL: window.location.hostname,
      HighValueInteraction: 'No',
      PNR: pnr,
      Tab: globalState.activeTab === 'trips' ? 'Recent Documents' : 'Search',
      CheckboxType: 'Select All',
    };
    sendAmplitudeData(event, props);
    // Amplitude end //
  };

  const toggleAllInvoices = () => {
    const mappedInvoices = invoices.map((invoice) => (
      {
        name: `Invoice_${invoice.clientid}_${invoice.invoicenumber.substr(-4)}`,
        number: invoice.invoicenumber,
        date: invoice.transactiondatetimeinutc,
        bucket: invoice.bucketname,
        bucketKey: invoice.bucketkey,
        docid: invoice.docid,
        total: invoice.totalamount,
        pnr: invoice.pnr,
      }
    ));
    const toSelect = [];
    for (let i = 0; i < invoices.length; i += 1) {
      const toAdd = {
        name: `Invoice_${invoices[i].clientid}_${invoices[i].invoicenumber.substr(-4)}`,
        number: invoices[i].invoicenumber,
        date: invoices[i].transactiondatetimeinutc,
        bucket: invoices[i].bucketname,
        bucketKey: invoices[i].bucketkey,
        docid: invoices[i].docid,
        total: invoices[i].totalamount,
        pnr: invoices[i].pnr,
      };
      console.log(JSON.stringify(toAdd));
      if (allSelected) {
        setGlobalState({
          selectedDocuments: globalState.selectedDocuments
            .filter((element) => element.number !== toAdd.number),
        });
      } else {
        console.log('Selecting all');
        if (!globalState.selectedDocuments.map((invoice) => invoice.number).includes(toAdd.number)) {
          toSelect.push(toAdd);
        } else {
          console.log(`Already selected: ${JSON.stringify(toAdd)}`);
        }
      }
    }
    if (allSelected) {
      setGlobalState({
        selectedDocuments: globalState.selectedDocuments
          .filter((element) => !mappedInvoices
            .map((invoice) => invoice.number).includes(element.number)),
      });
      setAllSelected(false);
      selectDocAmplitudeEvent(); // amplitude event
    } else {
      setGlobalState({ selectedDocuments: [...globalState.selectedDocuments, ...toSelect] });
      setAllSelected(true);
      selectDocAmplitudeEvent(); // amplitude event
    }
  };
  const formateDate = () => (
    date && `${date.substr(0, 4)}-${monthMap[parseInt(date.substr(5, 2), 10) - 1]}-${date.substr(8, 2)}`
  );

  return (
    <div className="Invoices-Invoice-Detail-Header">
      <div className="responsive-row">
        <div className="column">
          <div className="GBT-Booking-Ref-Title">
            GBT
            {' '}
            {t('booking_ref')}
            :
            {' '}
            {bookingRef}
          </div>
          <div className="Title-Trip-Date">
            {t('trip_date')}
            :
            {' '}
            {formateDate(date)}
          </div>
          {Array.isArray(name)
            ? (
              <div className="Multi-User-Name-Container">
                <MultipleUsers names={name} />
                <div className="Multi-User-Name">{name[0]}</div>
              </div>
            ) : (
              <div className="Traveler-Name">
                <div className="User-Name">
                  <ExpandableNameList names={name} />
                </div>
              </div>
            )}
        </div>
      </div>
      <div className={exportControlsClass}>
        <div className="column">
          <div className="Title-Controls">
            <div className="Pick-Up">
              <input checked={allSelected} onChange={toggleAllInvoices} type="checkbox" className="Checkbox" />
              <span className="Vertical-Center Left-Space">{[t('select_all'), ' ', t('documents')]}</span>
            </div>
          </div>
        </div>
        <div className="column">
          {/* <ExportControls active={selectedDocuments.length} /> */}
          <ExportControls emails={emails} focusDownload={focusDownload} focusEmail={focusEmail} pnr={pnr} />
        </div>
      </div>
    </div>
  );
}

InvoiceDetails.propTypes = {
  emails: PropTypes.arrayOf(PropTypes.string).isRequired,
  hideExportControls: PropTypes.bool.isRequired,
  bookingRef: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  // eslint-disable-next-line
  selectedDocuments: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line
  invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
};
