import React from 'react';

import styles from './Loader.module.css';

function Loader({ text }) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.loader} />
        {text}
      </div>
    </div>
  );
}
export default Loader;
