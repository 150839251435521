import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './SearchResultBar.module.css';
import SortDropdown from './SortDropdown';
import CheckOutsideClick from '../common/CheckOutsideClick';
import { readGlobalState } from '../../hooks/StateHook';

export default function SearchResultBar() {
  const [showMenu, setShowMenu] = useState(false);
  const [globalState] = readGlobalState();
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.resultCount}>
        <div className={styles.resultCountText}>
          {globalState.totalDocuments}
          {' '}
          {t('search_results')}
        </div>
      </div>
      <CheckOutsideClick onClickOutside={() => setShowMenu(false)} styleClass={styles.sort}>
        <div role="button" onClick={() => setShowMenu(!showMenu)} onKeyDown={() => setShowMenu(!showMenu)} tabIndex={0} className={styles.sortBtn}>
          <div className={styles.sortText}>{(t('sort'))}</div>
          <img className={styles.sortIcon} alt="Sort" src="https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/12x12/sort_down_active@1x.png" />
        </div>
        <div className={styles.menuContainer}>
          <SortDropdown show={showMenu} close={() => setShowMenu(false)} />
        </div>
      </CheckOutsideClick>
    </div>
  );
}

// SearchResultBar.propTypes = {
//   // eslint-disable-next-line
//   results: PropTypes.arrayOf(PropTypes.object).isRequired,
// };
