import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { writeGlobalState, readGlobalState } from '../../../hooks/StateHook';
import styles from './LanguageDropdown.module.css';

export default function LanguageDropdown() {
  const { t } = useTranslation();
  const languages = [
    { code: 'en', name: t('english') },
    { code: 'fr', name: t('french') },
    { code: 'de', name: t('german') },
    { code: 'da', name: t('danish') },
    { code: 'no', name: t('norwegian') },
    { code: 'se', name: t('swedish') },
  ];

  const [selectedVal, setSelectedVal] = useState(languages[0].code);
  const [setGlobalState] = writeGlobalState();
  const [allGlobals] = readGlobalState();

  /* istanbul ignore next */
  const changeLanguageSelection = (newLang) => {
    setSelectedVal(newLang);
  };

  /* istanbul ignore next */
  const onChangeHandler = (e) => {
    changeLanguageSelection(e.target.value);
    setGlobalState({ lang: allGlobals.lang });
  };

  // store data in global variable
  /* istanbul ignore next */
  useEffect(() => {
    setGlobalState({ lang: selectedVal });
  }, [selectedVal]);

  // returning dropdown values from props received
  return (
    <select
      value={selectedVal}
      onChange={onChangeHandler}
      className={styles.dropDown}
    >
      {languages.map(({ code, name }) => <option key={code} value={code}>{name}</option>)}
    </select>
  );
}
