import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from './EmailInput.module.css';

const validateEmail = (email) => {
  const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return re.test(String(email).toLowerCase());
};

export default function EmailInput({ changeHandler, email, id, emailCheck }) {
  const [validEmail, setValidEmail] = useState(true);
  const { t } = useTranslation();
  const handleChange = (e) => {
    if (validateEmail(e.target.value)) {
      setValidEmail(true);
      emailCheck(true);
      changeHandler(id, e);
    } else {
      setValidEmail(false);
      emailCheck(false);
      changeHandler(id, e);
    }
  };
  return (
    <div className={styles.container}>
      <input pattern="[0-9]{1,2}[/][0-9]{1,2}[/][0-9]{4}" className={styles.textField} value={email} placeholder="Enter email(s)" onChange={handleChange} />
      <div className={validEmail ? styles.line : styles.lineError} />
      {validEmail ? null : <div className={styles.errorText}>{t('invalid_email')}</div>}
    </div>
  );
}

EmailInput.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
};
