import React from 'react';

import styles from './NeedHelpModal.module.css';

export default function NeedHelpModal({ show, close }) {
  if (!show) {
    return null;
  }
  const svg = 'M31 25.4l13-13.1c.6-.6.6-1.5 '
  + '0-2.1l-2-2.1c-.6-.6-1.5-.6-2.1 0L26.8 '
  + '21.2c-.4.4-1 .4-1.4 0L12.3 8c-.6-.6-1.5-.6-2.1 '
  + '0l-2.1 2.1c-.6.6-.6 1.5 0 2.1l13.1 13.1c.4.4.4 1 '
  + '0 1.4L8 39.9c-.6.6-.6 1.5 0 2.1l2.1 2.1c.6.6 1.5.6 '
  + '2.1 0L25.3 31c.4-.4 1-.4 1.4 0l13.1 13.1c.6.6 1.5.6 '
  + '2.1 0L44 42c.6-.6.6-1.5 0-2.1L31 26.8c-.4-.4-.4-1 0-1.4z';
  return (
    <div className={styles.backdrop}>
      <div className={styles.container} id="modal-content-id-1" data-aura-rendered-by="253:0">
        <div className={styles.header}>
          <div className={styles.headerText}>Need Help?</div>
          <div className={styles.close} onClick={close} onKeyDown={close} role="button" tabIndex={0}>
            <svg className={styles.closeSvg}>
              <g>
                <path
                  d={svg}
                />
              </g>
            </svg>
          </div>
        </div>
        <div className={styles.content}>
          <p className={styles.groupHeader} data-aura-rendered-by="254:0">A</p>
          <hr className={styles.bottomLine} data-aura-rendered-by="254:0" />
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr />
            </tbody>
          </table>
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr>
                <td>
                  <p className={styles.country}><b>Algeria</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Argentina</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Armenia</b></p>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+1-312-340-2657</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+541153548151</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Email:</td>
                        <td className={styles.email}>portal.techsupport@amexgbt.com</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+1-312-340-2657</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+541153548151</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label} />
                        <td className={styles.email} />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <p className={styles.country}><b>Australia</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Austria</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Azerbaijan</b></p>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+ 61 2 9112 0916</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+431206092215</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Email:</td>
                        <td className={styles.email}>portal.techsupport@amexgbt.com</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+ 61 2 9112 0916</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+431206092215</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label} />
                        <td className={styles.email} />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <p className={styles.groupHeader} data-aura-rendered-by="254:0">B</p>
          <hr className={styles.bottomLine} data-aura-rendered-by="254:0" />
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr />
            </tbody>
          </table>
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr>
                <td>
                  <p className={styles.country}><b>Bahrain</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Bangladesh</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Belarus</b></p>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+1-312-340-2657</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+1-312-340-2658</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+370 5 208 1101</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+1-312-340-2657</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+1-312-340-2658</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+370 5 208 1101</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <p className={styles.country}><b>Belgium</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Bermuda</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Bosnia and Herzegovina</b></p>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+3225855166</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+1-312-340-2666</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+387 33 902 914</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+3225855166</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+1-312-340-2666</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+387 33 902 914</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <p className={styles.country}><b>Brazil</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Bulgaria</b></p>
                </td>
                <td />
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+551147008649</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+359 290 5 1066</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td />
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+551147008649</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+359 290 5 1066</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td />
              </tr>
            </tbody>
          </table>
          <p className={styles.groupHeader} data-aura-rendered-by="254:0">C</p>
          <hr className={styles.bottomLine} data-aura-rendered-by="254:0" />
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr />
            </tbody>
          </table>
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr>
                <td>
                  <p className={styles.country}><b>Canada</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Chile</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>China</b></p>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>1-844-487-4294</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Email:</td>
                        <td className={styles.email}>portal.techsupport@amexgbt.com</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+85230017172</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>1-844-487-4294</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label} />
                        <td className={styles.email} />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+85230017172</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <p className={styles.country}><b>Columbia</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Costa Rica</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Croatia</b></p>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+5712912608</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+506 0800 012 2057</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+385 15 544222</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+5712912608</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+06 0800 012 2057</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+385 15 544222</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <p className={styles.country}><b>Cyprus</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Czech</b></p>
                </td>
                <td />
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+357 77 78 87 83</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+20239018592</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td />
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+357 77 78 87 83</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+20239018592</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td />
              </tr>
            </tbody>
          </table>
          <p className={styles.groupHeader} data-aura-rendered-by="254:0">D</p>
          <hr className={styles.bottomLine} data-aura-rendered-by="254:0" />
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr />
            </tbody>
          </table>
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr>
                <td>
                  <p className={styles.country}><b>Denmark</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Dominican Republic</b></p>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.phone}>&nbsp;</td>
                        <td className={styles.phone} />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+4543310625</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+1 829 956 0098</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.phone}>&nbsp;</td>
                        <td className={styles.phone} />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+4543310625</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+1 829 956 0098</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.phone}>&nbsp;</td>
                        <td className={styles.phone} />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <p className={styles.groupHeader} data-aura-rendered-by="254:0">E</p>
          <hr className={styles.bottomLine} data-aura-rendered-by="254:0" />
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr />
            </tbody>
          </table>
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr>
                <td>
                  <p className={styles.country}><b>Ecuador</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Egypt</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>El Salvador</b></p>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+1-312-340-2669</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+1-312-340-2671</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+503 2113 3243</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+1-312-340-2669</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+1-312-340-2671</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+503 2113 3243</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <p className={styles.country}><b>Estonia</b></p>
                </td>
                <td />
                <td />
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+372 618 9566</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td />
                <td />
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+372 618 9566</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td />
                <td />
              </tr>
            </tbody>
          </table>
          <p className={styles.groupHeader} data-aura-rendered-by="254:0">F</p>
          <hr className={styles.bottomLine} data-aura-rendered-by="254:0" />
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr />
            </tbody>
          </table>
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr>
                <td>
                  <p className={styles.country}><b>Finland</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>France</b></p>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.phone}>&nbsp;</td>
                        <td className={styles.phone} />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+358972522423</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+33157329944</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.phone}>&nbsp;</td>
                        <td className={styles.phone} />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+358972522423</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+33157329944</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.phone}>&nbsp;</td>
                        <td className={styles.phone} />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <p className={styles.groupHeader} data-aura-rendered-by="254:0">G</p>
          <hr className={styles.bottomLine} data-aura-rendered-by="254:0" />
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr />
            </tbody>
          </table>
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr>
                <td>
                  <p className={styles.country}><b>Georgia</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Germany</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Ghana</b></p>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Email:</td>
                        <td className={styles.email}>portal.techsupport@amexgbt.com</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+4969380791994</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Email:</td>
                        <td className={styles.email}>portal.techsupport@amexgbt.com</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label} />
                        <td className={styles.email} />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+4969380791994</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label} />
                        <td className={styles.email} />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <p className={styles.country}><b>Gibraltar</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Greece</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Guatemala</b></p>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Email:</td>
                        <td className={styles.email}>portal.techsupport@amexgbt.com</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+30 231 308 2390</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+1-312-340-2673</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label} />
                        <td className={styles.email} />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+30 231 308 2390</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+1-312-340-2673</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <p className={styles.groupHeader} data-aura-rendered-by="254:0">H</p>
          <hr className={styles.bottomLine} data-aura-rendered-by="254:0" />
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr />
            </tbody>
          </table>
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr>
                <td>
                  <p className={styles.country}><b>Honduras</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Hong Kong</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Hungary</b></p>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+1-312-340-2674</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+85230112223</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+3617777611</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+1-312-340-2674</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+85230112223</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+3617777611</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <p className={styles.groupHeader} data-aura-rendered-by="254:0">I</p>
          <hr className={styles.bottomLine} data-aura-rendered-by="254:0" />
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr />
            </tbody>
          </table>
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr>
                <td>
                  <p className={styles.country}><b>India</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Indondesia</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Ireland</b></p>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>
                          +61 2 8228
                          1180
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Email:</td>
                        <td className={styles.email}>portal.techsupport@amexgbt.com</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+35315621300</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>
                          +61 2 8228
                          1180
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label} />
                        <td className={styles.email} />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+35315621300</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <p className={styles.country}><b>Israel</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Italy</b></p>
                </td>
                <td />
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>
                          +972
                          3-376-2315
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+390291387055</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td />
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>
                          +972
                          3-376-2315
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Out Of Country:</td>
                        <td className={styles.phone}>+390291387055</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td />
              </tr>
            </tbody>
          </table>
          <p className={styles.groupHeader} data-aura-rendered-by="254:0">J</p>
          <hr className={styles.bottomLine} data-aura-rendered-by="254:0" />
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr />
            </tbody>
          </table>
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr>
                <td>
                  <p className={styles.country}><b>Jamaica</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Japan</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Jordan</b></p>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+1-876-613-9199</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+81344773892</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>In Country:</td>
                        <td className={styles.phone}>+1-312-340-2677</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out Of
                          Country:
                        </td>
                        <td className={styles.phone}>+1-876-613-9199</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out Of
                          Country:
                        </td>
                        <td className={styles.phone}>+81344773892</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out Of
                          Country:
                        </td>
                        <td className={styles.phone}>+1-312-340-2677</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <p className={styles.groupHeader} data-aura-rendered-by="254:0">K</p>
          <hr className={styles.bottomLine} data-aura-rendered-by="254:0" />
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr />
            </tbody>
          </table>
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr>
                <td>
                  <p className={styles.country}><b>Kazakhstan</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Kenya</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Korea</b></p>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In Country:
                        </td>
                        <td className={styles.phone}>+1-312-340-2678</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In Country:
                        </td>
                        <td className={styles.phone}>+1-312-340-2655</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In Country:
                        </td>
                        <td className={styles.phone}>
                          + 82 2
                          3478 4006
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out Of
                          Country:
                        </td>
                        <td className={styles.phone}>+1-312-340-2678</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out Of
                          Country:
                        </td>
                        <td className={styles.phone}>+1-312-340-2655</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out Of
                          Country:
                        </td>
                        <td className={styles.phone}>
                          + 82
                          2 3478 4006
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <p className={styles.country}><b>Kuwait</b></p>
                </td>
                <td />
                <td />
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In Country:
                        </td>
                        <td className={styles.phone}>+1-312-340-2679</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td />
                <td />
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out Of
                          Country:
                        </td>
                        <td className={styles.phone}>+1-312-340-2679</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td />
                <td />
              </tr>
            </tbody>
          </table>
          <p className={styles.groupHeader} data-aura-rendered-by="254:0">L</p>
          <hr className={styles.bottomLine} data-aura-rendered-by="254:0" />
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr />
            </tbody>
          </table>
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr>
                <td>
                  <p className={styles.country}><b>Latvia</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Lebanon</b></p>
                </td>
                <td>
                  <p className={styles.country}><b>Lithuania</b></p>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>
                          +371
                          6610 1207
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Email:</td>
                        <td className={styles.email}>portal.techsupport@amexgbt.com</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>
                          +370
                          45 50 9570
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out Of
                          Country:
                        </td>
                        <td className={styles.phone}>
                          +371
                          6610 1207
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label} />
                        <td className={styles.email} />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out Of
                          Country:
                        </td>
                        <td className={styles.phone}>
                          +370
                          45 50 9570
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <p className={styles.country}><b>Luxembourg</b></p>
                </td>
                <td />
                <td />
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+3523420808364</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td />
                <td />
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out Of
                          Country:
                        </td>
                        <td className={styles.phone}>+3523420808364</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td />
                <td />
              </tr>
            </tbody>
          </table>
          <p className={styles.groupHeader} data-aura-rendered-by="254:0">M</p>
          <hr
            className={styles.bottomLine}
            data-aura-rendered-by="254:0"
          />
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr />
            </tbody>
          </table>
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr>
                <td>
                  <p className={styles.country}>
                    <b>Macedonia</b>
                  </p>
                </td>
                <td>
                  <p className={styles.country}>
                    <b>Malaysia</b>
                  </p>
                </td>
                <td>
                  <p className={styles.country}><b>Malta</b></p>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>
                          +30
                          213 0020359
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+60377248197</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>
                          +356
                          12 778 0689
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of Country:
                        </td>
                        <td className={styles.phone}>
                          +30
                          213 0020359
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of Country:
                        </td>
                        <td className={styles.phone}>+60377248197</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of Country:
                        </td>
                        <td className={styles.phone}>
                          +356
                          12 778 0689
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <p className={styles.country}><b>Mexico</b></p>
                </td>
                <td>
                  <p className={styles.country}>
                    <b>Montenegro</b>
                  </p>
                </td>
                <td>
                  <p className={styles.country}>
                    <b>Morocco</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+525512531074</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+1-312-340-2657</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>
                          +212
                          5200 01361
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of Country:
                        </td>
                        <td className={styles.phone}>+525512531074</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of Country:
                        </td>
                        <td className={styles.phone}>+1-312-340-2657</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of Country:
                        </td>
                        <td className={styles.phone}>
                          +212
                          5200 01361
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <p
            className={styles.groupHeader}
            data-aura-rendered-by="254:0"
          >
            N
          </p>
          <hr
            className={styles.bottomLine}
            data-aura-rendered-by="254:0"
          />
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr />
            </tbody>
          </table>
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr>
                <td>
                  <p className={styles.country}>
                    <b>Nepal</b>
                  </p>
                </td>
                <td>
                  <p className={styles.country}>
                    <b>Netherlands</b>
                  </p>
                </td>
                <td>
                  <p className={styles.country}>
                    <b>
                      New
                      Zealand
                    </b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+1-312-340-2698</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+31707007214</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>
                          +
                          649 913 0493
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of Country:
                        </td>
                        <td className={styles.phone}>+1-312-340-2698</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of Country:
                        </td>
                        <td className={styles.phone}>+31707007214</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of Country:
                        </td>
                        <td className={styles.phone}>
                          +
                          649 913 0493
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <p className={styles.country}>
                    <b>Nicaragua</b>
                  </p>
                </td>
                <td>
                  <p className={styles.country}>
                    <b>Nigeria</b>
                  </p>
                </td>
                <td>
                  <p className={styles.country}>
                    <b>Norway</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Email:</td>
                        <td className={styles.email}>portal.techsupport@amexgbt.com</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Email:</td>
                        <td className={styles.email}>portal.techsupport@amexgbt.com</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+4724055044</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label} />
                        <td className={styles.email} />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label} />
                        <td className={styles.email} />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of Country:
                        </td>
                        <td className={styles.phone}>+4724055044</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <p
            className={styles.groupHeader}
            data-aura-rendered-by="254:0"
          >
            O
          </p>
          <hr
            className={styles.bottomLine}
            data-aura-rendered-by="254:0"
          />
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr />
            </tbody>
          </table>
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr>
                <td>
                  <p className={styles.country}>
                    <b>Oman</b>
                  </p>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.phone}>&nbsp;</td>
                        <td className={styles.phone} />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.phone}>&nbsp;</td>
                        <td className={styles.phone} />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+1-312-340-2701</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.phone}>&nbsp;</td>
                        <td className={styles.phone} />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.phone}>&nbsp;</td>
                        <td className={styles.phone} />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of Country:
                        </td>
                        <td className={styles.phone}>+1-312-340-2701</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.phone}>&nbsp;</td>
                        <td className={styles.phone} />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.phone}>&nbsp;</td>
                        <td className={styles.phone} />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <p
            className={styles.groupHeader}
            data-aura-rendered-by="254:0"
          >
            P
          </p>
          <hr
            className={styles.bottomLine}
            data-aura-rendered-by="254:0"
          />
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr />
            </tbody>
          </table>
          <table data-aura-rendered-by="254:0">
            <tbody>
              <tr>
                <td>
                  <p className={styles.country}>
                    <b>Pakistan</b>
                  </p>
                </td>
                <td>
                  <p className={styles.country}>
                    <b>Panama</b>
                  </p>
                </td>
                <td>
                  <p className={styles.country}>
                    <b>Paraguay</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Email:</td>
                        <td className={styles.email}>portal.techsupport@amexgbt.com</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+5073084262</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+1-312-340-2702</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label} />
                        <td className={styles.email} />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of Country:
                        </td>
                        <td className={styles.phone}>+5073084262</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of Country:
                        </td>
                        <td className={styles.phone}>+1-312-340-2702</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <p className={styles.country}>
                    <b>Peru</b>
                  </p>
                </td>
                <td>
                  <p className={styles.country}>
                    <b>Philippines</b>
                  </p>
                </td>
                <td>
                  <p className={styles.country}>
                    <b>Poland</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>
                          +
                          51 1708 5473
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+633110681</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+48223064534</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of Country:
                        </td>
                        <td className={styles.phone}>
                          +
                          51 1708 5473
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of Country:
                        </td>
                        <td className={styles.phone}>+633110681</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of Country:
                        </td>
                        <td className={styles.phone}>+48223064534</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <p className={styles.country}>
                    <b>Portugal</b>
                  </p>
                </td>
                <td>
                  <p className={styles.country}>
                    <b>
                      Puerto
                      Rico
                    </b>
                  </p>
                </td>
                <td />
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+351800785138</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+1-787-589-8113</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td />
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of Country:
                        </td>
                        <td className={styles.phone}>+351800785138</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of Country:
                        </td>
                        <td className={styles.phone}>+1-787-589-8113</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td />
              </tr>
            </tbody>
          </table>
          <p
            className={styles.groupHeader}
            data-aura-rendered-by="254:0"
          >
            Q
          </p>
          <hr
            className={styles.bottomLine}
            data-aura-rendered-by="254:0"
          />
          <table
            data-aura-rendered-by="254:0"
          >
            <tbody>
              <tr />
            </tbody>
          </table>
          <table
            data-aura-rendered-by="254:0"
          >
            <tbody>
              <tr>
                <td>
                  <p className={styles.country}>
                    <b>Qatar</b>
                  </p>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.phone}>&nbsp;</td>
                        <td className={styles.phone} />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.phone}>&nbsp;</td>
                        <td className={styles.phone} />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Email:</td>
                        <td className={styles.email}>portal.techsupport@amexgbt.com</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.phone}>&nbsp;</td>
                        <td className={styles.phone} />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.phone}>&nbsp;</td>
                        <td className={styles.phone} />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label} />
                        <td className={styles.email} />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.phone}>&nbsp;</td>
                        <td className={styles.phone} />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.phone}>&nbsp;</td>
                        <td className={styles.phone} />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <p
            className={styles.groupHeader}
            data-aura-rendered-by="254:0"
          >
            R
          </p>
          <hr
            className={styles.bottomLine}
            data-aura-rendered-by="254:0"
          />
          <table
            data-aura-rendered-by="254:0"
          >
            <tbody>
              <tr />
            </tbody>
          </table>
          <table
            data-aura-rendered-by="254:0"
          >
            <tbody>
              <tr>
                <td>
                  <p className={styles.country}>
                    <b>Romania</b>
                  </p>
                </td>
                <td>
                  <p className={styles.country}>
                    <b>Russia</b>
                  </p>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.phone}>&nbsp;</td>
                        <td className={styles.phone} />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+40373788489</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+74999516224</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.phone}>&nbsp;</td>
                        <td className={styles.phone} />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of
                          Country:
                        </td>
                        <td className={styles.phone}>+40373788489</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of
                          Country:
                        </td>
                        <td className={styles.phone}>+74999516224</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.phone}>&nbsp;</td>
                        <td className={styles.phone} />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <p
            className={styles.groupHeader}
            data-aura-rendered-by="254:0"
          >
            S
          </p>
          <hr
            className={styles.bottomLine}
            data-aura-rendered-by="254:0"
          />
          <table
            data-aura-rendered-by="254:0"
          >
            <tbody>
              <tr />
            </tbody>
          </table>
          <table
            data-aura-rendered-by="254:0"
          >
            <tbody>
              <tr>
                <td>
                  <p className={styles.country}>
                    <b>
                      Saudi
                      Arabia
                    </b>
                  </p>
                </td>
                <td>
                  <p className={styles.country}>
                    <b>Serbia</b>
                  </p>
                </td>
                <td>
                  <p className={styles.country}>
                    <b>Singapore</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+1-312-340-2699</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>
                          +381
                          80
                          012
                          01
                          36
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+6567231467</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of
                          Country:
                        </td>
                        <td className={styles.phone}>+1-312-340-2699</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of
                          Country:
                        </td>
                        <td className={styles.phone}>
                          +381
                          80
                          012
                          01
                          36
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of
                          Country:
                        </td>
                        <td className={styles.phone}>+6567231467</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <p className={styles.country}>
                    <b>Slovakia</b>
                  </p>
                </td>
                <td>
                  <p className={styles.country}>
                    <b>Slovenia</b>
                  </p>
                </td>
                <td>
                  <p className={styles.country}>
                    <b>
                      South
                      Africa
                    </b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+421268622727</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>
                          +
                          386
                          1
                          828
                          22
                          60
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>
                          +
                          27
                          214
                          276
                          000
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of
                          Country:
                        </td>
                        <td className={styles.phone}>+421268622727</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of
                          Country:
                        </td>
                        <td className={styles.phone}>
                          +
                          386
                          1
                          828
                          22
                          60
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of
                          Country:
                        </td>
                        <td className={styles.phone}>
                          +
                          27
                          214
                          276
                          000
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <p className={styles.country}>
                    <b>Spain</b>
                  </p>
                </td>
                <td>
                  <p className={styles.country}>
                    <b>
                      Sri
                      Lanka
                    </b>
                  </p>
                </td>
                <td>
                  <p className={styles.country}>
                    <b>Swaziland</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+34910507754</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+1-312-340-2697</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+1-312-340-2855</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of
                          Country:
                        </td>
                        <td className={styles.phone}>+34910507754</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of
                          Country:
                        </td>
                        <td className={styles.phone}>+1-312-340-2697</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of
                          Country:
                        </td>
                        <td className={styles.phone}>+1-312-340-2855</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <p className={styles.country}>
                    <b>Sweden</b>
                  </p>
                </td>
                <td>
                  <p className={styles.country}>
                    <b>Switzerland</b>
                  </p>
                </td>
                <td />
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+46858770542</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+41445246719</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td />
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of
                          Country:
                        </td>
                        <td className={styles.phone}>+46858770542</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of
                          Country:
                        </td>
                        <td className={styles.phone}>+41445246719</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td />
              </tr>
            </tbody>
          </table>
          <p
            className={styles.groupHeader}
            data-aura-rendered-by="254:0"
          >
            T
          </p>
          <hr
            className={styles.bottomLine}
            data-aura-rendered-by="254:0"
          />
          <table
            data-aura-rendered-by="254:0"
          >
            <tbody>
              <tr />
            </tbody>
          </table>
          <table
            data-aura-rendered-by="254:0"
          >
            <tbody>
              <tr>
                <td>
                  <p className={styles.country}>
                    <b>Taiwan</b>
                  </p>
                </td>
                <td>
                  <p className={styles.country}>
                    <b>Tanzania</b>
                  </p>
                </td>
                <td>
                  <p className={styles.country}>
                    <b>Thailand</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+886277436406</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Email:</td>
                        <td className={styles.email}>portal.techsupport@amexgbt.com</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+6628449076</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of
                          Country:
                        </td>
                        <td className={styles.phone}>+886277436406</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label} />
                        <td className={styles.email} />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of
                          Country:
                        </td>
                        <td className={styles.phone}>+6628449076</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <p className={styles.country}>
                    <b>Trinidad &amp; Tobago</b>
                  </p>
                </td>
                <td>
                  <p className={styles.country}>
                    <b>Tunisia</b>
                  </p>
                </td>
                <td>
                  <p className={styles.country}>
                    <b>Turkey</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>Email:</td>
                        <td className={styles.email}>portal.techsupport@amexgbt.com</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+1-312-340-2716</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+1-312-340-2709</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label} />
                        <td className={styles.email} />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of
                          Country:
                        </td>
                        <td className={styles.phone}>+1-312-340-2716</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of
                          Country:
                        </td>
                        <td className={styles.phone}>+1-312-340-2709</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <p
            className={styles.groupHeader}
            data-aura-rendered-by="254:0"
          >
            U
          </p>
          <hr
            className={styles.bottomLine}
            data-aura-rendered-by="254:0"
          />
          <table
            data-aura-rendered-by="254:0"
          >
            <tbody>
              <tr />
            </tbody>
          </table>
          <table
            data-aura-rendered-by="254:0"
          >
            <tbody>
              <tr>
                <td>
                  <p className={styles.country}>
                    <b>UAE</b>
                  </p>
                </td>
                <td>
                  <p className={styles.country}>
                    <b>Uganda</b>
                  </p>
                </td>
                <td>
                  <p className={styles.country}>
                    <b>Ukraine</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>
                          +971
                          800035770013
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>
                          +
                          1-312-340-2657
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>
                          +380
                          7
                          499
                          9516141
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of
                          Country:
                        </td>
                        <td className={styles.phone}>
                          +971
                          800035770013
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of
                          Country:
                        </td>
                        <td className={styles.phone}>
                          +
                          1-312-340-2657
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of
                          Country:
                        </td>
                        <td className={styles.phone}>
                          +380
                          7
                          499
                          9516141
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <p className={styles.country}>
                    <b>
                      United
                      Kingdom
                    </b>
                  </p>
                </td>
                <td />
                <td />
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>+442037884018</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td />
                <td />
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of
                          Country:
                        </td>
                        <td className={styles.phone}>+442037884018</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td />
                <td />
              </tr>
            </tbody>
          </table>
          <p
            className={styles.groupHeader}
            data-aura-rendered-by="254:0"
          >
            V
          </p>
          <hr
            className={styles.bottomLine}
            data-aura-rendered-by="254:0"
          />
          <table
            data-aura-rendered-by="254:0"
          >
            <tbody>
              <tr />
            </tbody>
          </table>
          <table
            data-aura-rendered-by="254:0"
          >
            <tbody>
              <tr>
                <td>
                  <p className={styles.country}>
                    <b>Venezuela</b>
                  </p>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.phone}>&nbsp;</td>
                        <td className={styles.phone} />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.phone}>&nbsp;</td>
                        <td className={styles.phone} />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          In
                          Country:
                        </td>
                        <td className={styles.phone}>
                          +58
                          212
                          335
                          8891
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.phone}>&nbsp;</td>
                        <td className={styles.phone} />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.phone}>&nbsp;</td>
                        <td className={styles.phone} />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>
                          Out
                          Of
                          Country:
                        </td>
                        <td className={styles.phone}>
                          +58
                          212
                          335
                          8891
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.phone}>&nbsp;</td>
                        <td className={styles.phone} />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.phone}>&nbsp;</td>
                        <td className={styles.phone} />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
