import React from 'react';
import styles from './PDFFrame.module.css';

export default function PDFFrame() {
  const pdfSource = `${window.pdfData}`;
  console.log(`${window.pdfData}`);

  const iframeLoaded = () => {
    const internalDoc = document.getElementById('pdfIFrame').contentWindow.document;
    const style = document.createElement('style');
    style.textContent = 'html body {'
            + '    height: 100vh;'
            + '    width: 100%;'
            + '    margin: 0;'
            + '    overflow-x: hidden;'
            + '    overflow-y: hidden;'
            + '}';
    // console.log(internalDoc.getElementsByTagName('head'));
    internalDoc.getElementsByTagName('head')[0].appendChild(style);
  };

  return (
    <div className={styles.container}>
      <iframe
        title="frame"
        id="pdfIFrame"
        src={pdfSource}
        onLoad={iframeLoaded}
        width="100"
        height="100"
        style={{ overflow: 'hidden',
          overflowX: 'hidden',
          overflowY: 'hidden',
          height: '100vh',
          width: '100%',
          position: 'absolute',
          top: '0px',
          left: '0px',
          right: '0px',
          bottom: '0px',
          border: '0' }}
      />
    </div>
  );
}
