import React from 'react';
import PropTypes from 'prop-types';
import InvoiceDetails from './InvoiceDetails';
import DocumentCard from './DocumentCard';
import { readGlobalState } from '../../hooks/StateHook';

import styles from './InvoiceGroup.module.css';

export default function InvoiceGroup({ invoiceMap }) {
  const [globalState] = readGlobalState(); // NOSONAR both are required to access global state

  const getUniqueNames = (value, index, self) => self.indexOf(value) === index;

  return (
    <>
      {Object.keys(invoiceMap).map((bookingRef) => (
        <div className={styles.background}>
          <InvoiceDetails
            selectedDocuments={globalState.selectedDocuments}
            name={invoiceMap[bookingRef][0].travelerName}
            // eslint-disable-next-line no-nested-ternary
            emails={invoiceMap[bookingRef][0].emails
              ? invoiceMap[bookingRef][0].emails.map((email) => email.email) : invoiceMap[bookingRef][0].booking_emails ? invoiceMap[bookingRef][0].booking_emails : []}
            bookingRef={bookingRef}
            date={invoiceMap[bookingRef][0].departureDate}
            invoices={invoiceMap[bookingRef]}
            pnr={invoiceMap[bookingRef][0].pnr}
            names={invoiceMap[bookingRef].map((invoice) => invoice.travelerName).flat().filter(getUniqueNames)}
          />
          <div>
            <div className="Element-Divider-1px" />
            {invoiceMap[bookingRef].map((rawInvoice) => ({
              name: `Invoice_${rawInvoice.clientid}_${rawInvoice.invoicenumber.substr(-4)}`,
              number: rawInvoice.invoicenumber,
              date: rawInvoice.invoiceIssueDate,
              bucket: rawInvoice.bucketname,
              bucketKey: rawInvoice.bucketkey,
              docid: rawInvoice.docid,
              total: rawInvoice.totalamount,
              currency: rawInvoice.currency,
              pnr: rawInvoice.pnr,
              // eslint-disable-next-line no-nested-ternary
              emails: rawInvoice.emails ? rawInvoice.emails.map((email) => email.email) : rawInvoice.booking_emails ? rawInvoice.booking_emails : [],
            })).map((invoice) => (
              <DocumentCard
                selected={
                  globalState.selectedDocuments.find((o) => o.number === invoice.number)
                }
                name={invoice.name}
                date={invoice.date}
                number={invoice.number}
                total={invoice.total}
                bucket={invoice.bucket}
                bucketKey={invoice.bucketKey}
                docid={invoice.docid}
                currency={invoice.currency}
                pnr={invoice.pnr}
                emails={invoice.emails}
              />
            ))}
          </div>
        </div>
      ))}
    </>
  );
}

InvoiceGroup.propTypes = {
  // eslint-disable-next-line
  invoiceMap: PropTypes.arrayOf(PropTypes.object).isRequired,
};
