import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './MultipleUsers.module.css';
import users from '../../images/GBT-Mktg-09-Group-RGB-2-db-bb.svg';

export default function MultipleUsers({ names }) {
  const [showPopup, setShowPopup] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const { t } = useTranslation();

  const handleIconHover = (event) => {
    const isHover = event.type === 'mouseenter';
    setIsHovering(isHover && !showPopup); // Show on hover only if not clicked.
  };

  const handleIconClick = () => {
    setShowPopup(!showPopup);
    setIsHovering(false); // Hide on hover pop-up when clicked
  };

  return (
    <div
      className={styles.container}
      onClick={handleIconClick}
      onKeyUp={handleIconClick}
      onMouseEnter={handleIconHover}
      onMouseLeave={handleIconHover}
      role="button"
      tabIndex={0}
    >
      <img alt="MultipleUser Logo" src={users} />
      {
        isHovering && !showPopup && (
          <div className={styles.popupheading}>{t('multiple_travelers')}</div>
        )
}
      {showPopup && (
        <div className={styles.popupcontainer}>
          <div className={names.length > 5 ? styles.scrollableList : null}>
            {names.map((name, index) => (
              <li>
                {' '}
                {index + 1}
                .
                {name}
                {' '}
              </li>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
