import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '../../hooks/StateHook';
import { sendAmplitudeData } from '../../amplitude/Amplitude';

import SidebarTab from './SidebarTab';
import SidebarTripList from './SidebarTripList';
import SidebarSearch from './SidebarSearch';

import { getTripsForEmail } from '../../apis/OneDDSAPI';

export default function Sidebar() {
  const [globalState, setGlobalState] = useGlobalState();

  const [trips, setTrips] = useState([]);
  const [loadTripsError, setLoadTripsError] = useState(false);
  const [tripsToDisplay, setTripsToDisplay] = useState(10);
  const [totalTrips, setTotalTrips] = useState(0);
  const [searchAfter, setSearchAfter] = useState();

  const viewEventAmplitude = (e) => {
    // Amplitude start //
    const event = e;
    const searchDocsProps = {
      Description: 'When user clicks on "Search" tab within OneDDS',
      Source: 'ONEDDS',
      URL: window.location.hostname,
      HighValueInteraction: 'Yes',
    };
    const recentInvoicesProps = {
      Description: 'When user clicks on "Recent Documents" tab within OneDDS',
      Source: 'ONEDDS',
      URL: window.location.hostname,
      HighValueInteraction: 'Yes',
    };
    const viewMoreProps = {
      Description: 'User clicks "View More" to see more than 10 invoices on Recent Documents tab',
      Source: 'ONEDDS',
      URL: window.location.hostname,
      HighValueInteraction: 'No',
      Tab: 'Recent Documents',
    };
    if (e === 'Viewed Search Documents') sendAmplitudeData(event, searchDocsProps);
    else if (e === 'Viewed Recent Invoices') sendAmplitudeData(event, recentInvoicesProps);
    else sendAmplitudeData(event, viewMoreProps);
    // Amplitude end //
  };

  const changeTab = (tab) => {
    if (tab === 'trips') {
      setGlobalState({
        searchResult: undefined,
        mainResult: undefined,
        refineSearch: undefined,
        filterToggle: false,
        selectedDocuments: [],
      });
      viewEventAmplitude('Viewed Recent Invoices'); // amplitude event
    } else {
      setGlobalState({ activeBookingRef: '', selectedDocuments: [] });
      viewEventAmplitude('Viewed Search Documents'); // amplitude event
    }
    setGlobalState({ activeTab: tab });
  };

  const convertToTime = (dateTime) => new Date(dateTime).getTime();
  const { t } = useTranslation();
  // Ignoring the functions used in useEffect
  // Current test frameworks can't handle it
  /* istanbul ignore next */
  const fetchTrips = () => {
    setGlobalState({ loadingTrips: true });
    getTripsForEmail(globalState.userEmail, undefined, undefined, 'recent')
      .then((response) => response.json())
      .then((data) => {
        setGlobalState({ rawDocuments: data.documents, loadingTrips: false });
        setSearchAfter(data.search_after);
        setTotalTrips(data.totalDocuments);
        let result = data.documents.map((document) => ({
          bookingRef: document.pnr,
          date: document.invoiceIssueDate,
          name: document.travelerName,
          invoiceIssueDate: document.invoiceIssueDate,
          invoicenumber: document.invoicenumber,
        }));
        result = result.sort((a, b) => convertToTime(b.invoiceIssueDate) - convertToTime(a.invoiceIssueDate));
        setTrips(result);
        if (globalState.tripsPageSize >= data.totalDocuments) {
          setTripsToDisplay(data.totalDocuments);
        } else {
          setTripsToDisplay(globalState.tripsPageSize);
        }
      })
      .catch((err) => {
        console.log(err);
        setGlobalState({ loadingTrips: false });
        setLoadTripsError(true);
      });
  };
  /* istanbul ignore next */
  useEffect(() => {
    fetchTrips();
  }, []);

  const viewMoreTrips = () => {
    getTripsForEmail(globalState.userEmail, searchAfter, undefined, 'recent')
      .then((response) => response.json())
      .then((data) => {
        setGlobalState({ rawDocuments: globalState.rawDocuments.concat(data.documents), loadingTrips: false });
        setSearchAfter(data.search_after);
        let result = data.documents.map((document) => ({
          bookingRef: document.pnr,
          date: document.invoiceIssueDate,
          name: document.travelerName,
          invoiceIssueDate: document.invoiceIssueDate,
          invoicenumber: document.invoicenumber,
        }));
        result = result.sort((a, b) => convertToTime(b.invoiceIssueDate) - convertToTime(a.invoiceIssueDate));
        setTrips(trips.concat(result));
        if (tripsToDisplay + globalState.tripsPageSize > trips.concat(result).length) {
          setTripsToDisplay(trips.concat(result).length);
          console.log(`Setting page size to ${trips.concat(result).length}`);
        } else {
          setTripsToDisplay(tripsToDisplay + globalState.tripsPageSize);
          console.log(`Setting page size to ${tripsToDisplay + globalState.tripsPageSize}`);
        }
      })
      .catch((err) => {
        console.log(err);
        setGlobalState({ loadingTrips: false });
        setLoadTripsError(true);
      });
    viewEventAmplitude('View More'); // amplitude event
  };

  let content;

  switch (globalState.activeTab) {
    case 'trips':
      content = (
        <SidebarTripList
          loading={globalState.loadingTrips}
          loadTripsError={loadTripsError}
          tripsToDisplay={tripsToDisplay}
          trips={trips}
          totalTrips={totalTrips}
          activeBookingRef={globalState.activeBookingRef}
          activeInvoiceNumber={globalState.activeInvoiceNumber}
          viewMoreTrips={viewMoreTrips}
        />
      );
      break;
    case 'search':
      content = (
        <SidebarSearch
          setFoundTrips={setTrips}
          setFoundTripsToDisplay={setTripsToDisplay}
        />
      );
      break;
      /* istanbul ignore next */
    default:
      content = (
        <SidebarTripList
          loading={globalState.loadingTrips}
          loadTripsError={loadTripsError}
          tripsToDisplay={tripsToDisplay}
          trips={trips}
          totalTrips={totalTrips}
          activeBookingRef={globalState.activeBookingRef}
          viewMoreTrips={viewMoreTrips}
        />
      );
      break;
  }

  return (
    <div className="Trips-Background column">
      <div className="Documents-Heading">{t('documents')}</div>
      <div className="Sidebar-Tabs">
        <div className="row">
          <SidebarTab name="trips" active={globalState.activeTab === 'trips'} clickAction={changeTab} tabText={t('recent_documents')} />
          <SidebarTab name="search" active={globalState.activeTab === 'search'} clickAction={changeTab} tabText={t('search')} />
        </div>
      </div>
      {content}
    </div>
  );
}
