import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './SendEmailModal.module.css';
import EmailInput from './EmailInput';
import EditableInvoiceList from './EditableInvoiceList';
import SendEmailModalCheckbox from './SendEmailModalCheckbox';
import { sendAmplitudeData } from '../../../amplitude/Amplitude';
import { sendInvoiceEmail } from '../../../apis/OneDDSAPI';
import { useGlobalState } from '../../../hooks/StateHook';

export default function SendEmailModal({ show, closeModal, invoices, emailArr }) {
  const [globalState, setGlobalState] = useGlobalState();
  const { t } = useTranslation();
  // const [globalState] = readGlobalState();
  const [emailList, setEmailList] = useState(['']);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const maxNumberOfEmailsToSend = 4;
  if (!show || !invoices) {
    return null;
  }

  // convert array to object having key as array and values as boolean false
  const converter = (arr) => {
    const obj = {};
    arr.forEach((data) => { obj[data] = false; });
    return JSON.stringify(obj);
  };

  const emailListChange = (id, e) => {
    setEmailList(emailList.map((email, index) => {
      if (index !== id) return email;
      return e.target.value;
    }));
  };

  const close = () => {
    setEmailList(['']);
    setSendingEmail(false);
    closeModal();
  };

  const addEmailAmplitudeEvent = () => {
    // Amplitude start //
    const event = 'Select email address';
    const props = {
      Description: 'User selects or deselects individual email address within "Email document" pop up',
      Source: 'ONEDDS',
      URL: window.location.hostname,
      HighValueInteraction: 'No',
      PNR: invoices[0].pnr,
      Tab: globalState.activeTab === 'trips' ? 'Recent Documents' : 'Search',
      EmailAddress: emailList[emailList.length - 1],
    };
    sendAmplitudeData(event, props);
    // Amplitude end //
  };

  const removeEmailAmplitudeEvent = (index) => {
    // Amplitude start //
    const event = 'Select email address';
    const props = {
      Description: 'User selects or deselects individual email address within "Email document" pop up',
      Source: 'ONEDDS',
      URL: window.location.hostname,
      HighValueInteraction: 'No',
      PNR: invoices[0].pnr,
      Tab: globalState.activeTab === 'trips' ? 'Recent Documents' : 'Search',
      EmailAddress: emailList[index],
    };
    sendAmplitudeData(event, props);
    // Amplitude end //
    addEmailAmplitudeEvent();
  };

  const addEmail = () => {
    if (emailList.length < maxNumberOfEmailsToSend) {
      // Amplitude start //
      const event = 'Select email address';
      const props = {
        Source: 'ONEDDS',
        URL: window.location.hostname,
        HighValueInteraction: 'No',
        PNR: invoices[0].pnr,
        Tab: globalState.activeTab === 'trips' ? 'Recent Documents' : 'Search',
        EmailAddress: emailList[emailList.length - 1],
      };
      sendAmplitudeData(event, props);
      // Amplitude end //
      addEmailAmplitudeEvent();

      // Temp array is needed so that React will recognize the state has changed
      const tempArray = [].concat(emailList);
      tempArray.push('');
      setEmailList(tempArray);
      // console.log('***email change request3***');
      // console.log('Added');
    }
  };

  const removeEmail = (index) => {
    if (emailList.length > 1) {
      console.log(`Removing ${emailList[index]} at position ${index}`);

      // Amplitude start //
      const event = 'Select email address';
      const props = {
        Source: 'ONEDDS',
        URL: window.location.hostname,
        HighValueInteraction: 'No',
        PNR: invoices[0].pnr,
        Tab: globalState.activeTab === 'trips' ? 'Recent Documents' : 'Search',
        EmailAddress: emailList[index],
      };
      sendAmplitudeData(event, props);
      // Amplitude end //
      removeEmailAmplitudeEvent(index);

      const tempArray = [].concat(emailList);
      tempArray.splice(index, 1);
      setEmailList(tempArray);
      console.log(`Ending list: ${tempArray}`);
      console.log('***email change request2***');
    }
  };

  const sendEmailAmplitudeEvent = (success, InvoiceNumArray, emailsToSend) => {
    // Amplitude start //
    const event = 'Send email';
    const props = {
      Description: 'User clicks on "Send" button within Email Document pop up',
      Source: 'ONEDDS',
      URL: window.location.hostname,
      HighValueInteraction: 'No',
      PNR: invoices[0].pnr,
      Tab: globalState.activeTab === 'trips' ? 'Recent Documents' : 'Search',
      InvoiceNumber: InvoiceNumArray,
      EmailAddress: emailsToSend,
      Success: success ? 'Yes' : 'No',
    };
    sendAmplitudeData(event, props);
    // Amplitude end //
  };

  const sendEmail = () => {
    console.log('***email send event***');
    if (sendingEmail) {
      return;
    }
    setSendingEmail(true);
    const documents = invoices.map((invoice) => ({
      docid: invoice.docid,
      bucketkey: invoice.bucketKey,
    }));
    const emails = emailList;
    const emailsToSend = emails.concat(globalState.emailChecks).filter((el) => el);
    console.log(`${JSON.stringify(emailsToSend)} and ${JSON.stringify(documents)}`);
    console.log(documents.length);
    sendInvoiceEmail(emailsToSend, documents)
      .then((res) => res.json())
      .then((res) => {
        console.log(JSON.stringify(res));
        const InvoiceNumArray = invoices.map((data) => data.number);
        if (!res.errorType) {
          setSendingEmail(false);
          setGlobalState({ emailSuccess: true });
          closeModal();
          sendEmailAmplitudeEvent(true, InvoiceNumArray, emailsToSend); // amplitde event
        } else {
          setSendingEmail(false);
          setGlobalState({ emailFailure: true });
          closeModal();
          sendEmailAmplitudeEvent(false, InvoiceNumArray, emailsToSend); // amplitude event
        }
      });
  };

  const emailCheck = (bool) => {
    setValidEmail(bool);
  };

  return (
    <div className={styles.modalBackdrop}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <div className={styles.headerText}>{t('email_document')}</div>
          <div onClick={close} onKeyDown={close} role="button" tabIndex={0}>
            <img className={styles.close} src="https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/16x16/close_glyphs_white@1x.png" alt="close" />
          </div>
        </div>
        <div className={styles.modalContent}>
          <div className={styles.heading}>
            {t('email_modal_question')}
          </div>
          <div className={styles.basicText}>
            {t('send_mail_to_multiple')}
            {globalState.role && (globalState.role[0] === 'invoice-manager' && <span>{t('add_recipients')}</span>)}
          </div>
          <SendEmailModalCheckbox
            values={converter(emailArr)}
            title={t('email')}
            storeCheckedDataAs="emailChecks"
            checkboxType="emailArr"
            pnr={invoices.length && invoices[0].pnr}
          />
          {globalState.role && (globalState.role[0] === 'invoice-manager' && (<div className={styles.andOr}>And/Or</div>))}
          {globalState.role && (globalState.role[0] === 'invoice-manager' && emailList
            .map((email, index) => (
              <div className={styles.emails}>
                <div className={styles.removeGroup}>
                  <EmailInput id={index} email={email} changeHandler={emailListChange} emailCheck={emailCheck} />
                  {emailList.length > 1
                    ? (
                      <div
                        className={styles.removeButton}
                        onClick={() => removeEmail(index)}
                        // onKeyDown={() => removeEmail(index)}
                        onKeyDown={() => console.log('remove email')}
                        role="button"
                        tabIndex={0}
                      >
                        <div className={styles.addButtonText}>X</div>
                      </div>
                    )
                    : ''}
                </div>
                {index === emailList.length - 1 && emailList.length !== maxNumberOfEmailsToSend
                  ? (
                    <div
                      className={styles.addButton}
                      onClick={addEmail}
                      // onKeyDown={addEmail}
                      onKeyDown={() => { console.log('add Email'); }}
                      role="button"
                      tabIndex={0}
                    >
                      <div className={styles.addButtonText}>+</div>
                    </div>
                  )
                  : ''}
              </div>
            )))}
        </div>
        <div className={styles.line} />
        <EditableInvoiceList invoices={invoices} />
        <div className={styles.line} />
        <div className={styles.modalFooter}>
          <button
            type="button"
            disabled={invoices.length === 0
          || (emailList[0] === '' ? globalState.emailChecks.length === 0 : !validEmail)}
            onClick={sendEmail}
            onKeyDown={sendEmail}
            tabIndex={0}
            // eslint-disable-next-line
            className={invoices.length === 0
              || emailList[0] === '' || !validEmail
              ? (invoices.length === 0 || globalState.emailChecks.length === 0 ? styles.sendButton2 : styles.sendButton)
              : styles.sendButton}
          >
            <div className={styles.verticalCenter}>
              <div className={styles.sendButtonText}>{sendingEmail ? t('sending') : t('send')}</div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

SendEmailModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  // eslint-disable-next-line
  invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
};
