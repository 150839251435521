/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './EmailListModal.module.css';
import Loader from '../../common/Loader';
import { readAssistantEmail } from '../../../apis/OneDDSAPI';
import { useGlobalState } from '../../../hooks/StateHook';

export default function EmailListModal({ closeModal, documentname, emails }) {
  const [updatingAssistants, setUpdatingAssistants] = useState(false);
  const [updatingAllAssistants, setUpdatingAllAssistants] = useState(false);
  const [assistantEmailList, setAssistantEmailList] = useState([]);
  const [emailObj, setEmailObj] = useState(new Set());
  const [globalState] = useGlobalState();
  // const [filteredEmailList, setFilteredEmailList] = useState([]);
  const { t } = useTranslation();

  const payloadread = {
    legend: {
      target: 'es',
      action: 'search',
    },
    body: {
      bookingEmail: globalState.userEmail,
      loggedUser: globalState.name,
      loggedUserEmail: globalState.userEmail,
    },
  };

  /* istanbul ignore next */
  const handleReadAssistantEmail = () => {
    setUpdatingAssistants(true);
    readAssistantEmail(payloadread)
      .then((data) => data.json())
      .then((data) => {
        setAssistantEmailList(data);
        setUpdatingAssistants(false);
      })
      .catch((err) => {
        console.error(err);
        setUpdatingAssistants(false);
      });
  };

  /* istanbul ignore next */
  const handleReadAllAssistantEmail = async (email) => {
    const payloadreadall = {
      legend: {
        target: 'es',
        action: 'search',
      },
      body: {
        bookingEmail: email,
        loggedUser: globalState.name,
        loggedUserEmail: globalState.userEmail,
      },
    };
    try {
      let data = await readAssistantEmail(payloadreadall);
      data = await data.json();
      let check;
      if (data.body[0] === 'No Records Available') {
        check = data.body[0];
      } else {
        check = data.body.map((assistantEmail) => <li className={styles.assistant_list}>{assistantEmail._source.assistantEmail}<br /></li>);
      }
      // setEmailObj(emailObj.add(check)); // Makes a Set of NRA and valid emails.
      return check;
    } catch (err) {
      console.error(err);
      setUpdatingAllAssistants(false);
    }
  };

  const filteredEmail = async (filteredEmailList) => {
    // eslint-disable-next-line no-plusplus
    setUpdatingAllAssistants(true);
    const emailObj1 = new Set();
    let allAssistant;
    for (let i = 0; i < filteredEmailList.length; i++) {
      allAssistant = await handleReadAllAssistantEmail(filteredEmailList[i]);
      emailObj1.add(allAssistant);
    }
    setEmailObj(emailObj1);
    setUpdatingAllAssistants(false);
  };

  const checkBookingEmail = () => {
    if (emails.includes(globalState.userEmail)) {
      // eslint-disable-next-line max-len, no-underscore-dangle
      return assistantEmailList.body && assistantEmailList.body.map((assistantEmail) => (assistantEmail === 'No Records Available' ? assistantEmail : <li className={styles.assistant_list}>{assistantEmail._source.assistantEmail}</li>));
    }
    return t('assistant_added_by_you_error');
  };

  useEffect(() => {
    const filteredEmailList = emails.filter((email) => email !== globalState.userEmail);
    handleReadAssistantEmail();
    filteredEmail(filteredEmailList);
  }, []);

  const correctEmail = [...emailObj].filter((email) => email !== 'No Records Available');
  const hasNoRecords = [...emailObj].every((email) => email === 'No Records Available');

  const isCorrectEmail = () => {
    if (hasNoRecords) {
      return <>No Records Available</>;
    }
    return (
      <div>
        {
            correctEmail && (
              correctEmail.map((email) => <li className={styles.assistant_list}>{email}</li>)
            )
          }
      </div>
    );
  };

  console.log('emailObj', emailObj);
  return (
    <div className={styles.backDrop}>
      <div className={styles.modal}>
        <div className={styles.m1}>
          <div
            className={styles.closeIcon}
            role="button"
            tabIndex={0}
            onClick={closeModal}
            onKeyDown={() => { console.log('Close Modal'); }}
          >
            <img className={styles.close} src="https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/16x16/close_glyphs_white@1x.png" alt="close" />
          </div>
          <div className={styles.header}>
            {documentname}
          </div>
          <div className={styles.container}>
            <div className={styles.body}>
              {t('email_from_booking')}
              <div className={styles.email_list}>
                {emails.map((email) => (
                  <div className={styles.email_list_lowercase}>
                    {email}
                    <br />
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.body}>
              {t('assistant_added_by_you')}
              <div className={styles.email_list}>
                {updatingAssistants ? <Loader /> : checkBookingEmail()}
              </div>
            </div>
            <div className={styles.body}>
              {t('assistant_added_by_other')}
              <div className={styles.email_list}>
                {updatingAllAssistants ? <Loader /> : isCorrectEmail()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
