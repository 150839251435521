import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './DateInput.module.css';

export default function DateInput({ clickAction, date, standalone }) {
  const { t } = useTranslation();
  return (
    <div className={!standalone ? styles.container : styles.fieldContainer}>
      {/* <div className={styles.labelText}>{label}</div> */}
      <div className="row">
        <div onClick={clickAction} onKeyDown={clickAction} className={styles.icon} role="button" aria-label="Date Input" tabIndex={0}>
          <input
            pattern="[0-9]{4}[/][0-9]{1,2}[/][0-9]{2}"
            className={!standalone ? styles.dateField : styles.standaloneDateField}
            placeholder={t('select_date')}
          // eslint-disable-next-line
          value={date
            ? date.date > 9 ? `${date.year}-${date.fullMonth}-${date.date}` : `${date.year}-${date.fullMonth}-${0}${date.date}`
            : ''}
          />
        </div>
      </div>
      {!standalone ? <div className={styles.line} /> : null}
    </div>
  );
}

DateInput.propTypes = {
  clickAction: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
};
