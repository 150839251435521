import React from 'react';
import styles from './Modal.module.css';

export default function Modal({ closeModal, modalHeader, modalTitle, modalBody, buttonLabel, children, confirm }) {
  return (
    <div className={styles.backDrop}>
      <div className={styles.modal}>
        <div className={styles.m1}>
          <div
            className={styles.closeIcon}
            role="button"
            tabIndex={0}
            onClick={closeModal}
            onKeyDown={() => { console.log('Close Modal'); }}
          >
            <img className={styles.close} src="https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/16x16/close_glyphs_white@1x.png" alt="close" />
          </div>
          <div className={styles.header}>
            {modalHeader}
            <div className={styles.title}>
              {modalTitle}
            </div>
          </div>
          <div className={styles.container}>
            {children}
            <div className={styles.body}>
              {modalBody}
            </div>
            {
              buttonLabel
                ? (
                  <>
                    <div className={styles.separator} />
                    <div className={styles.button}>
                      <button
                        type="button"
                        className={styles.addButton}
                        onClick={confirm}
                        onKeyDown={confirm}
                        tabIndex={0}
                      >
                        {buttonLabel}
                      </button>
                    </div>
                  </>
                ) : null
            }
          </div>
        </div>
      </div>
    </div>
  );
}
