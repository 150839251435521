import React from 'react';
import { sendAmplitudeData } from '../../amplitude/Amplitude';
import { writeGlobalState } from '../../hooks/StateHook';

export default function GbtLogo() {
  const [setGlobalState] = writeGlobalState();

  const homeAmplitudeEvent = () => {
    // Amplitude start //
    const event = 'Home';
    const props = {
      Description: 'User clicks on "Home" in top nav to return to GBTA homepage',
      Source: 'ONEDDS',
      URL: window.location.hostname,
      HighValueInteraction: 'No',
    };
    sendAmplitudeData(event, props);
    // Amplitude end //
  };

  const refreshAll = () => {
    setGlobalState({
      activeTab: 'trips',
      searchResult: undefined,
      mainResult: undefined,
      // refineSearch: undefined,
      selectedDocuments: [],
      counter: 0,
      activeBookingRef: '',
      // rawDocuments: [],
      searchResultRaw: [],
      filterToggle: false,
      clearFilter: true,
      amountMinMaxArr: ['', ''],
      travelTypeArr: [],
      countryFilterArr: [],
      documentTypes: '',
      sort: undefined,
    });
    homeAmplitudeEvent(); // amplitude Event
  };

  return (
    <span
      onClick={refreshAll}
      // onKeyUp={refreshAll}
      onKeyUp={() => console.log('Home Button Clicked')}
      role="button"
      tabIndex={0}
    >
      <img className="Image-Container" alt="GBT Logo" src="https://cdn.amexgbt.com/brand19/logos/gbt_logo_desktop.png" />
    </span>
  );
}
