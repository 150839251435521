import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import GlobeIcon from './components/links/GlobeIcon';
import InvoicesTab from './components/tabs/InvoicesTab';
import Sidebar from './components/sidebar/Sidebar';
import MainContent from './components/main/MainContent';
import Footer from './components/Footer/Footer';
import { useGlobalState } from './hooks/StateHook';
import { setAuthState, isTopUser } from './apis/OneDDSAPI';
import { setAmplitudeUserId, setAmplitudeUserProperties, sendAmplitudeData } from './amplitude/Amplitude';
import GbtLogo from './components/links/GbtLogo';
import Toggler from './components/links/Toggler/Toggler';
import HomeTab from './components/tabs/HomeTab';
import TopUserDashboard from './components/topusers/TopUserDashboard';
import NavigationBar from './components/tabs/NavigationBar';

const loadingMarkup = (
  <div className="Loading">
    <h3>Loading..</h3>
  </div>
);

function App() {
  const [globalState, setGlobalState] = useGlobalState();
  const { authState, oktaAuth } = useOktaAuth();
  const [authPending, setAuthPending] = useState(true);
  const [topUser, setTopUser] = useState(false);
  const [topUserIcon, setTopUserIcon] = useState(false);

  console.log(oktaAuth);

  useEffect(() => {
    const refArr = JSON.parse(sessionStorage.getItem('ref'));

    if ((refArr.length === 1 && !refArr.includes('')) || refArr.length > 1) {
    // Amplitude start //
      const event = 'Viewed OneDDS';
      const props = {
        Description: 'When user clicks on "Invoices" from GBT Account homepage',
        Source: 'ONEDDS',
        URL: window.location.hostname,
        HighValueInteraction: 'No',
        // ref: refArr,
      };
      sendAmplitudeData(event, props);
    // Amplitude end //
    }
  }, []);

  const parseJwt = (jwt) => {
    const base64Url = jwt.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
    return JSON.parse(jsonPayload);
  };

  const handleTopUser = () => {
    isTopUser()
      .then((response) => response.json())
      .then((data) => {
        setTopUser(data);
        if (data[0] && data[0].ReportingIDs) {
          setGlobalState({ reportingID: (data[0].ReportingIDs.split(',')) });
        } else {
          setGlobalState({ reportingID: '' });
        }
        setTopUserIcon(true);
      });
  };

  useEffect(() => {
    console.log('In use effect', authState);
    setAuthState(authState);
    setAuthPending(authState.isPending);
  }, [authState]);

  useEffect(() => {
    handleTopUser();
  }, []);

  if (authState.isAuthenticated && !globalState.userEmail) {
    // more than 30 mins then route back to okta.
    setAuthState(authState);
    setAuthPending(authState.isPending);
    const decodedToken = parseJwt(authState.accessToken.accessToken);
    setGlobalState({ userEmail: decodedToken.sub });
    setGlobalState({ name: authState.idToken.claims.name });
    setGlobalState({ role: authState.idToken.claims.roles });
    const userData = {
      email: authState.idToken.claims.email,
      name: authState.idToken.claims.name,
      role: authState.idToken.claims.roles,
      country: authState.idToken.claims.country_of_employment,
      clientID: authState.idToken.clientId,
    };
    setAmplitudeUserId(decodedToken.sub);
    setAmplitudeUserProperties(userData);
  }

  if (authPending === true) {
    return loadingMarkup;
  }

  console.log(topUser, 'Top User');

  return (
    <div className="main-container">
      <div className="Mobile-Only">
        <div className="Header-Background">
          <Toggler />
          <div className="Icon-Container">
            <GlobeIcon />
          </div>
        </div>
        {(globalState.activeBookingRef === '' && globalState.searchResult === undefined) || globalState.refineSearch
          ? (
            <div className="Page-Background">
              <div className="Background-Mask row">
                <Sidebar />
              </div>
            </div>
          )
          : <MainContent />}
        <Footer />
      </div>
      {topUserIcon && topUser.length !== 0
        ? (
          <div className="Desktop-Only">
            <NavigationBar />
            {globalState.renderTab === 'invoice'
              ? (
                <>
                  <div className="Page-Background">
                    <div className="Background-Mask row">
                      <Sidebar />
                      <MainContent />
                    </div>
                  </div>
                  <Footer />
                </>
              )
              : (
                <>
                  <div className="Separation-Line" />
                  <TopUserDashboard />
                </>
              )}
          </div>
        )
        : (
          <div className="Desktop-Only">
            <div className="Header-Background">
              <div className="Header-Container">
                <GbtLogo />
                <div className="Tabs-Background">
                  <HomeTab />
                  <div className="tabDivider" />
                  <InvoicesTab label="invoice" />
                </div>
                <div className="Icon-Container">
                  <GlobeIcon />
                </div>
              </div>
            </div>
            {globalState.renderTab === 'invoice'
              ? (
                <>
                  <div className="Page-Background">
                    <div className="Background-Mask row">
                      <Sidebar />
                      <MainContent />
                    </div>
                  </div>
                  <Footer />
                </>
              )
              : (
                <>
                  <div className="Separation-Line" />
                  <TopUserDashboard />
                </>
              )}
          </div>
        ) }
    </div>
  );
}

export default App;
