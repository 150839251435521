/* eslint-disable */
const config = {
  dev: {
    okta: {
      issuer: 'https://daccess.amexgbt.com/oauth2/ausz84pwfrB5aryeI0h7',
      clientId: '0oa103b14cjllMOxz0h8',
      redirectUri: `${window.location.origin}/login/callback`,
      pkce: true,
      scope: 'openid profile email',
      postLogoutRedirectUri: `${window.location.origin}/logout`,
    },
    oneDdsApi: {
      baseUrl: '0pl5r9c9v6.execute-api.eu-west-1.amazonaws.com/dev',
    },
    salesforce: {
      url: 'https://daccess.amexgbt.com',
    },
    amplitude: {
      apikey: 'eb5075811547953c0966f4a8437b5208',
    },
  },
  preprod: {
    okta: {
      issuer: 'https://qaccess.amexgbt.com/oauth2/aus10n5j2olhgUrmA0h8',
      clientId: '0oazdznbawedDHJjB0h7',
      redirectUri: `${window.location.origin}/login/callback`,
      pkce: true,
      scope: 'openid profile email',
      postLogoutRedirectUri: `${window.location.origin}/logout`,
    },
    oneDdsApi: {
      baseUrl: 'o31sbxn2sk.execute-api.eu-west-1.amazonaws.com/qa',
    },
    salesforce: {
      url: 'https://qaccess.amexgbt.com',
    },
    amplitude: {
      apikey: 'f5b9059a70d4556a2802fd953d483b7b',
    },
  },
  prod: {
    okta: {
      issuer: 'https://access.amexgbt.com/oauth2/aus1m6ohn3htEQZEd0h8',
      clientId: '0oa1mc4ze16Yg7rnx0h8',
      redirectUri: `${window.location.origin}/login/callback`,
      pkce: true,
      scope: 'openid profile email',
      postLogoutRedirectUri: `${window.location.origin}/logout`,
    },
    oneDdsApi: {
      baseUrl: 'ba3946ack8.execute-api.eu-west-1.amazonaws.com/prd',
    },
    salesforce: {
      url: 'https://access.amexgbt.com',
    },
    amplitude: {
      apikey: '89b3bbc32c26eff336190d0b2226803c',
    },
  },
};

const getEnv = () => {
  if (window.location.origin.includes('dtripdocs')) {
    return 'dev';
  } if (window.location.origin.includes('qtripdocs')) {
    return 'preprod';
  } if (window.location.origin.includes('tripdocs')) {
    return 'prod';
  }
  return 'dev';
};

const getConfigForEnvironment = () => config[getEnv()];

export { getConfigForEnvironment };