import React from 'react';
import { useTranslation } from 'react-i18next';
import MultipleUsers from './MultipleUsers';
import styles from './TopUserDocument.module.css';
import Checkbox from './Checkbox';

function TopUserDocument({ document, selectDocument, deselectDocument, selected }) {
  const { t } = useTranslation();
  const monthMap = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  const formateDate = (date) => date && `${date.substr(8, 2)}-${monthMap[parseInt(date.substr(5, 2), 10) - 1]}-${date.substr(0, 4)}`;
  // YYYY MMM DD instead of DD MMM YYYY

  if (selected) {
    console.log(`${document.invoicenumber} is selected!`);
  }

  const handleClick = (event) => {
    if (event.target.checked) {
      selectDocument();
    } else {
      deselectDocument();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.column}>
          {t('document')}
          :
          <span className={styles.documentNumber}>
            {document.countrycode}
            _
            {document.transactiontype ? document.transactiontype.substring(0, 1) : 'NONE'}
            _
            {document.invoicenumber}
          </span>
        </div>
        <div className={styles.column}>
          {[].concat(document.travelerName).length > 1
            ? (
              <div className={styles.labelcontainer}>
                <div className={styles.label}>
                  {t('lead_traveler')}
                  :
                </div>
                <span className={styles.nameWrap}>
                  {document.travelerName[0]}
                </span>
                <MultipleUsers names={document.travelerName} />
              </div>
            )
            : (
              <div className={styles.labelcontainer}>
                <div className={styles.label}>
                  {t('lead_traveler')}
                  :
                </div>
                <span className={styles.nameWrap}>
                  {document.travelerName}
                </span>
              </div>
            )}
        </div>
        <div className={styles.column}>
          {t('total')}
          :
          <span className={styles.documentNumber}>
            {document.totalamount}
            {' '}
            {document.currency}
          </span>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          {t('invoice_date')}
          :
          <span className={styles.documentNumber}>
            {formateDate(document.invoiceIssueDate)}
          </span>
        </div>
        <div className={styles.column}>
          <div className={styles.labelcontainer}>
            <div className={styles.label}>
              {t('customer_name')}
              :
            </div>
            <span className={styles.nameWrap}>
              {document.clientname}
            </span>
          </div>
        </div>
        <div className={styles.column}>
          {t('trip_date')}
          :
          <span className={styles.documentNumber}>
            {formateDate(document.departureDate)}
          </span>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={styles.inline}>
            {t('select_document')}
            :
            <div className={styles.Checkbox}>
              <Checkbox onCheck={handleClick} defaultChecked={selected} />
            </div>
          </div>
        </div>
        <div className={styles.column}>
          {t('customer_num')}
          :
          <span className={styles.documentNumber}>
            {document.dknumber}
          </span>
        </div>
        <div className={styles.column}>
          {t('GBT_booking_ref')}
          :
          <span className={styles.documentNumber}>
            {document.pnr}
          </span>
        </div>
      </div>
    </div>
  );
}

export default TopUserDocument;
