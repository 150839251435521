import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '../../hooks/StateHook';

export default function MobileNav() {
  const [globalState, setGlobalState] = useGlobalState();
  const { t } = useTranslation();
  const backToDocuments = () => {
    setGlobalState({ activeBookingRef: '', searchResult: undefined, refineSearch: false, activeTab: 'trips' });
  };

  const backToSearch = () => {
    setGlobalState({ activeBookingRef: '', searchResult: undefined, activeTab: 'search' });
  };

  const refineSearch = () => {
    setGlobalState({ refineSearch: true });
  };

  const search = (
    <div onClick={backToSearch} onKeyDown={backToSearch} role="button" tabIndex={0} className="Mobile-Nav-Text">
      <img alt="Search" src="https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/16x16/search_gray05@1x.png" />
      <div style={{ width: '16px' }} />
      {t('search')}
    </div>
  );
  const filter = (
    <div onClick={refineSearch} onKeyDown={refineSearch} role="button" tabIndex={0} className="Mobile-Nav-Text">
      <img alt="Filter" src="https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/16x16/filter_gray05@1x.png" />
      <div style={{ width: '16px' }} />
      {t('filter_search')}
    </div>
  );

  return (
    <div className="Mobile-Only MobileNav">
      <div style={{ width: '100%' }} className="row">
        <div style={{ width: '195px' }} className="clickable column" onClick={backToDocuments} onKeyDown={backToDocuments} role="button" tabIndex={0}>
          <div className="Mobile-Nav-Text">
            <img alt="Document" src="https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/16x16/document_gray05@1x.png" />
            <div style={{ width: '16px' }} />
            {t('recent_documents')}
          </div>
        </div>
        <div className="column-divider">
          <div className="Mobile-Nav-Divider" />
        </div>
        <div style={{ width: '130px' }} className="column-medium">
          <div className="Mobile-Nav-Text">
            {globalState.searchResult === undefined
              ? search
              : filter}
          </div>
        </div>
      </div>
    </div>
  );
}
