import React from 'react';
import PropTypes from 'prop-types';
import styles from './EditableInvoiceList.module.css';
import InvoiceListItem from './InvoiceListItem';

export default function EditableInvoiceList({ invoices }) {
  return (
    <div className={styles.container}>
      {invoices.map((invoice) => <InvoiceListItem invoice={invoice} />)}
    </div>
  );
}

EditableInvoiceList.propTypes = {
  // eslint-disable-next-line
  invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
};
