import React from 'react';
import PropTypes from 'prop-types';
import { writeGlobalState, readGlobalState } from '../../hooks/StateHook';
import styles from './SuccessBanner.module.css';
import { sendAmplitudeData } from '../../amplitude/Amplitude';

export default function SuccessBanner({ message }) {
  const [setGlobalState] = writeGlobalState();
  const [globalState] = readGlobalState();
  const closeSuccess = () => {
    setGlobalState({ emailSuccess: false });
    // Amplitude start //
    const event = 'Dismiss email result message';
    const props = {
      Description: 'User clicks "X" on message for whether document email was successful or not',
      Source: 'ONEDDS',
      URL: window.location.hostname,
      HighValueInteraction: 'No',
      Tab: globalState.activeTab === 'trips' ? 'Recent Documents' : 'Search',
      Message_Type: 'Success',
    };
    sendAmplitudeData(event, props);
    // Amplitude end //
  };

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <img className={styles.checkmark} src="https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/28x28/success_green@1x.png" alt="success" />
      </div>
      <div className={styles.text}>{message}</div>
      <div onClick={closeSuccess} role="button" tabIndex={0} onKeyUp={closeSuccess}>
        <img className={styles.closemark} src="https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/16x16/close_glyphs_gray05@1x.png" alt="failure" />
      </div>
    </div>
  );
}

SuccessBanner.propTypes = {
  message: PropTypes.string.isRequired,
};
