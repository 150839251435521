// import { Link } from 'react-router-dom';
import { React, useState } from 'react';
import './Toggler.css';
// import HomeTab from '../../tabs/HomeTab';
import Home from './Home';
import Invoices from './Invoices';
// import InvoicesTab from '../../tabs/InvoicesTab';
import GbtLogo from '../GbtLogo';

function Navbar() {
  const [sidebar, setSidebar] = useState(false);
  const toggleSideBar = () => setSidebar(!sidebar);

  return (
    <>
      <div className="navbar">
        <div className="menu-bars">
          <span onClick={toggleSideBar} onKeyUp={toggleSideBar} role="button" tabIndex={0}>
            <img className="burgericon" alt="Burger-icon" src="https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/24x24/burger_nav_active@1x.png" />
          </span>
        </div>
      </div>
      <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
        <ul className="nav-menu-items">
          <li className="navbar-toggle">
            <div className="menu-bars" role="button" tabIndex={0} onClick={toggleSideBar} onKeyUp={toggleSideBar}>
              <img className="close" alt="Close Side Bar" src="https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/12x12/close_active@1x.png" />
              <GbtLogo />
            </div>
          </li>
          <li className="nav-text">
            <span role="button" tabIndex={0} onKeyUp={toggleSideBar} onClick={toggleSideBar}>
              <Home />
            </span>
          </li>
          <li className="nav-text">
            <span role="button" tabIndex={0} onClick={toggleSideBar} onKeyUp={toggleSideBar}>
              <Invoices />
            </span>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Navbar;
