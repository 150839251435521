import React from 'react';
import GbtLogo from '../links/GbtLogo';
import GlobeIcon from '../links/GlobeIcon';
import HomeTab from './HomeTab';
import InvoicesTab from './InvoicesTab';
import TopUserTab from './TopUserTab';

function NavigationBar() {
  return (
    <div className="Header-Background">
      <div className="Header-Container">
        <GbtLogo />
        <div className="Tabs-Background">
          <HomeTab />
          <div className="tabDivider" />
          <InvoicesTab label="invoice" />
          <div className="tabDivider" />
          <TopUserTab label="topuser" />
        </div>
        <div className="Icon-Container">
          <GlobeIcon />
        </div>
      </div>
    </div>
  );
}

export default NavigationBar;
