/* eslint-disable */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useGlobalState } from '../../hooks/StateHook';
import invoice from '../../images/GBT-Mktg-05-Receipt-Plus-RGB-1-db.svg';

export default function InvoicesTab({ label }) {
  const [globalState, setGlobalState] = useGlobalState();
  const isActiveFunc = (match, location) => {
    return match !==null && match.url === location.pathname;
  }

  return (
    <div className="Header-Tab">
      <div className="Link-line-1">
        <NavLink
          to="/"
          className={({ isActive}) => (isActive ? 'active' : '')}
          isActive={isActiveFunc}
        >
          <img
            className="Style-Icons-Universal-Invoice-invoice_active"
            onClick={() => setGlobalState({ renderTab: 'invoice' })}
            src={invoice}
            alt="Invoice"
          />
        </NavLink>
      </div>
      {/* {globalState.renderTab === label ? <div className="Line" /> : ''} */}
    </div>
  );
}
