/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Pagination.module.css';
import Footer from '../Footer/Footer';

function Pagination({ totalDocuments, handlePagination, currentPage, pageSize }) {
  const { t } = useTranslation();
  const [footerCollapsed, setFooterCollapsed] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const paginationNumbers = [];
  const pageNumbers = totalDocuments === 0 ? 0 : Math.ceil(totalDocuments / pageSize);
  for (let i = 1; i <= pageNumbers; i += 1) {
    paginationNumbers.push(i);
  }

  const toggleFooter = () => {
    setFooterCollapsed(!footerCollapsed);
  };

  const handleScroll = () => {
    const position = window.scrollY;
    // console.log(`Scroll position: ${position}`)
    setScrollPosition(position);
  };

  const toTop = () => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={styles.pageSpacer}>
      <div className={styles.container}>
        <div className={styles.border}>
          <div className={styles.entries}>
            {t('showing')}
            {' '}
            {totalDocuments === 0 ? 0 : pageSize > totalDocuments ? 1 : 1 + (Number(currentPage - 1) * Number(pageSize))}
            {' '}
            {t('to1')}
            {' '}
            {Math.min(currentPage * pageSize, totalDocuments)}
            {' '}
            {t('of')}
            {' '}
            {totalDocuments}
            {' '}
            {t('entries')}
            .
          </div>
          <div onClick={toggleFooter} onKeyDown={toggleFooter} role="button" tabIndex={0} className={styles.footerToggle}>
            <div className={footerCollapsed ? styles.arrowUp : styles.arrowDown} />
          </div>
          {/* <a href="#" className={styles.scrollTop}>Back to Top &#8593;</a> */}
          <div
            onClick={toTop} 
            onKeyDown={toTop} 
            role="button" 
            tabIndex={0}
            class={scrollPosition > 400 ? styles.moveupContainer : styles.hideMoveupContainer}>
            <div class={styles.moveupCircle}>
              <div
                class={styles.chevronup}
                // onClick={toTop}
              ></div>
            </div>
            <div class={styles.moveupText}>{t('backtotop')}</div>
          </div>
          <div className={styles.pagination}>
            {/* <div
              className={styles.backtotop}
              onClick={toTop}
            ></div> */}
            <button type="button" onClick={() => handlePagination(1)}>{'<<'}</button>
            <button type="button" onClick={() => handlePagination(Number(currentPage) - 1)}>{'<'}</button>
            {paginationNumbers.filter((pageNumber) => currentPage >= pageNumber).map((pageNumber) => (
              <button
                className={currentPage === pageNumber ? styles.active : styles.notActive}
                type="button"
                key={pageNumber}
                onClick={() => handlePagination(pageNumber)}
              >
                {pageNumber}
              </button>
            ))}
            <button
              type="button"
              onClick={() => handlePagination(Number(currentPage) + 1)}
              disabled={currentPage === pageNumbers}
            >
              {'>'}

            </button>
          </div>
        </div>
        {footerCollapsed ? '' : <Footer />}
      </div>
    </div>

  );
}

export default Pagination;
