/*eslint-disable*/
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './SidebarSearch.module.css';
import DateInput from './DateInput';
import { sendAmplitudeData } from '../../amplitude/Amplitude';
import DatePicker from './DatePicker';

import { searchTrips, dateRangeSearch } from '../../apis/OneDDSAPI';
import { writeGlobalState, readGlobalState } from '../../hooks/StateHook';
import FilterContent from './filter/FilterContent';

export default function SidebarSearch() {
  const [setGlobalState] = writeGlobalState(); // NOSONAR needed to set global state
  const [globalState] = readGlobalState();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [dateError, setDateError] = useState('');
  const [searchError, setSearchError] = useState(false);
  const [dateToPick, setDateToPick] = useState('start');

  const { t } = useTranslation();

  const handleSearchValueChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === 'Enter') textSearch(searchValue);
  };

  const displayDatePicker = (startOrEnd) => {
    setDateToPick(startOrEnd);
    setShowDatePicker(!showDatePicker);
  };

  const selectStartDate = (date) => {
    setStartDate(date);
    setShowDatePicker(false);
  };

  const selectEndDate = (date) => {
    setEndDate(date);
    setShowDatePicker(false);
  };
  const searchAmplitudeEvent = (term) => {
    // Amplitude start //
    const event = 'Search';
    const props = {
      Description: 'User clicks on "Search" on Search tab',
      Source: 'ONEDDS',
      URL: window.location.hostname,
      HighValueInteraction: 'No',
      Tab: globalState.activeTab === 'trips' ? 'Recent Documents' : 'Search',
      SearchTerm: term,
    };
    sendAmplitudeData(event, props);
    // Amplitude end //
  };

  const searchByDateAmplitudeEvent = (start, end) => {
    // Amplitude start //
    const event = 'Search by invoices date(s)';
    const props = {
      Description: 'User clicks on "Search by invoice date(s)" on Search tab',
      Source: 'ONEDDS',
      URL: window.location.hostname,
      HighValueInteraction: 'No',
      Tab: globalState.activeTab === 'trips' ? 'Recent Documents' : 'Search',
      StartDate: [start.year, start.fullMonth, start.date].join('-'),
      EndDate: [end.year, end.fullMonth, end.date].join('-'),
    };
    sendAmplitudeData(event, props);
    // Amplitude end //
  };

  const textSearch = (term) => {
    console.log('##################text search');
    setStartDate(undefined);
    setEndDate(undefined);
    searchTrips(term)
      .then((response) => response.json())
      .then((data) => {
        searchAmplitudeEvent(term); // amplitude event
        setSearchError(false);
        const map = {};
        data.documents.forEach((invoice) => {
          // setSearchError(false);
          // console.log(`Processing searched invoice: ${invoice.pnr}`);
          const existingList = map[invoice.pnr];
          // console.log(JSON.stringify(existingList));
          if (!existingList) {
            map[invoice.pnr] = [invoice];
            console.log('Setting');
            // console.log(JSON.stringify(map));
          } else {
            existingList.push(invoice);
          }
        });
        // for debugging purpose - GDD 8950 - START
        // prints Booking Refs and invoice dates attached to it.
        for (const i in map) {
          console.log(`${i}: ${map[i].length}`);
          const data = new Set();
          let tranDate;
          for (const j of map[i]) {
            // data.add(j.transactiondatetimeinutc)
            tranDate = new Date(j.transactiondatetimeinutc);
            data.add(`${tranDate.getFullYear()}-${tranDate.getMonth()}-${tranDate.getDate()}`);
          }
          console.log(`Dates: ${JSON.stringify([...data])}`);
          data.clear();
        }
        // for debugging purpose - GDD 8950 - END
        setGlobalState({
          searchResult: map,
          loadingTrips: false,
          searchResultRaw: data.documents,
          mainResult: map,
          filterToggle: true,
          refineSearch: false,
          selectedDocuments: [],
          amountMinMaxArr: ['', ''],
          travelTypeArr: [],
          countryFilterArr: [],
          documentTypes: '',
          clearFilter: true,
          totalDocuments: Number(data.totalDocuments),
          remDocs: Number(data.totalDocuments) - 100,
          search_term: term,
          search_after: data.search_after,
          docs: [],
          search_date: [],
        });
        // console.log(JSON.stringify(map));
      }).catch((err) => {
        console.log(err);
        setSearchError(true);
      });
    // }
  };

  const dateSearch = (start, end) => {
    console.log('######date search')
    setSearchValue('')
    if (!(start && end)) {
      setDateError(t('search_error_message'));
      return;
    }
    setDateError('');
    /* istanbul ignore next */
    dateRangeSearch(start, end)
      .then((response) => {
        if(response.status !== undefined) return response.json()
        return response;
      })
      .then((data) => {
        console.log('1###########')
        //console.log(data);
        searchByDateAmplitudeEvent(start, end); // amplitude event
        setSearchError(false);
        const map = {};
        data.documents.forEach((invoice) => {
          // setSearchError(false);
          //console.log(`Processing searched invoice: ${invoice.pnr}`);
          const existingList = map[invoice.pnr];
          //console.log(JSON.stringify(existingList));
          if (!existingList) {
            map[invoice.pnr] = [invoice];
            //console.log('Setting');
            //console.log(JSON.stringify(map));
          } else {
            existingList.push(invoice);
          }
        });
        // setGlobalState({ searchResult: map, loadingTrips: false });
        setGlobalState({
          searchResult: map,
          loadingTrips: false,
          searchResultRaw: data.documents,
          mainResult: map,
          filterToggle: true,
          amountMinMaxArr: ['', ''],
          travelTypeArr: [],
          countryFilterArr: [],
          documentTypes: '',
          selectedDocuments: [],
          clearFilter: true,
          totalDocuments: Number(data.totalDocuments),
          remDocs: Number(data.totalDocuments) - 100,
          search_date: [start,end],
          search_after: data.search_after,
          docs: [],
          search_term: '',
        });
        //console.log(JSON.stringify(map));
      }).catch((err) => {
        console.log(err);
        setSearchError(true);
      });
  };

  return (
    <div className={styles.container}>
      {searchError ? <div className={styles.searchError}>{t('error_message')}</div> : ''}
      <div className={styles.description}>
        {t('search_by')}
      </div>
      <div className={styles.searchBox}>
        <input
          onKeyDown={(e) => handleSearchKeyDown(e)}
          onChange={(e) => handleSearchValueChange(e)}
          value={searchValue}
          placeholder={t('search_invoices')}
          type="text"
          className={styles.searchField}
        />
        <div
          onClick={() => textSearch(searchValue)}
          onKeyDown={() => textSearch(searchValue)}
          // onKeyDown={() => console.log('Search btn clicked')}
          role="button"
          tabIndex={0}
          className={styles.searchButton}
        >
          {/* <div className={[styles.icon, styles.iconSm, styles.dlsIconSearch].join(' ')} /> */}
          {t('search')}
        </div>
      </div>
      <div className={styles.description2}>
        {/* <span style={{ fontWeight: 'bold' }}>OR</span>
        {' '}
        search by a range of trip dates: */}
        <div className={styles.liner} />
        <div className={styles.nameLiner}>OR</div>
        <div className={styles.liner} />
      </div>
      <div className={styles.dateFieldsContainer}>
        <DateInput date={startDate} clickAction={() => displayDatePicker('start')} label={t('start_date')} />
        <DateInput date={endDate} clickAction={() => displayDatePicker('end')} label={t('end_date')} />
      </div>
      {showDatePicker ? <DatePicker selectStartDate={selectStartDate} selectEndDate={selectEndDate} dateToSet={dateToPick} start={startDate} end={endDate} /> : ''}
      {dateError && (<div className={styles.dateError}>{dateError}</div>)}
      <div
        onClick={() => dateSearch(startDate, endDate)}
        // onKeyDown={() => dateSearch(startDate, endDate)}
        onKeyDown={() => console.log('Search by date button clicked')}
        role="button"
        tabIndex={0}
        className={styles.searchButton}
      >
        {t('search_by_document_date')}
      </div>
      <FilterContent />
    </div>
  );
}
