import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './i18next';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from './AppRouter';
import { initAmplitude } from './amplitude/Amplitude';
import reportWebVitals from './reportWebVitals';

const loadingMarkup = (
  <div className="py-4 text-center">
    <h3>Loading..</h3>
  </div>
);

initAmplitude();

// ReactDOM.createRoot(
//   <React.StrictMode>
//     <Router>
//       <Suspense fallback={loadingMarkup}>
//         <AppRouter />
//       </Suspense>
//     </Router>
//   </React.StrictMode>,
//   document.getElementById('root'),
// );

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Suspense fallback={loadingMarkup}>
        <AppRouter />
      </Suspense>
    </Router>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
