import amplitude from 'amplitude-js';
import { getConfigForEnvironment } from '../config/env-config';

const { amplitude: { apikey } } = getConfigForEnvironment();
// const API_KEY = '';
export const initAmplitude = () => {
  amplitude.getInstance().init(apikey);
};

export const setAmplitudeUserId = (userId) => {
  amplitude.getInstance().setUserId(userId);
};

export const setAmplitudeUserProperties = (properties) => {
  amplitude.getInstance().setUserProperties(properties);
};

export const sendAmplitudeData = (eventType, eventProperties) => {
  amplitude.getInstance().logEvent(eventType, eventProperties);
};

// export const sendAmplitudeDataWithoutProps = (eventType, eventProperties) => {
//   amplitude.getInstance().logEvent(eventType, eventProperties);
// };

// export const sendAmplitudeData = (eventType) => {
//   amplitude.getInstance().logEvent(eventType);
// };

// export const setAmplitudeUserDevice = (installationToken) => {
//   amplitude.getInstance().setDeviceId(installationToken);
// };

// export const regenerateAmplitudeDeviceId = () => {
//   amplitude.getInstance().regenerateDeviceId();
// };
