/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import account from '../../images/style-icons-glyphs-icons-general-account-account-default.png';
import { writeGlobalState } from '../../hooks/StateHook';
import { sendAmplitudeData } from '../../amplitude/Amplitude';
import ExpandableNameList from '../main/ExpandableNameList';
import MultipleUsers from '../topusers/MultipleUsers';

export default function InvoiceCard({
  activeBookingRef, activeInvoiceNumber, bookingRef, invoiceNumber, date, name, trips, tripsToDisplay,
}) {
  const [setGlobalState] = writeGlobalState(); // NOSONAR this is required to set the global state
  const { t } = useTranslation();
  const isMobile = (window.screen.width <= 800);

  const viewPNRAmplitudeEvent = (ref) => {
    // Amplitude start //
    const event = 'Viewed PNR documents';
    // // eslint-disable-next-line
    // const xx = ref;
    const props = {
      Description: 'User clicks on new PNR',
      Source: 'ONEDDS',
      URL: window.location.hostname,
      HighValueInteraction: 'No',
      PNR: ref,
    };
    sendAmplitudeData(event, props);
    // Amplitude end //
  };

  const selectRef = (ref) => {
    setGlobalState({
      activeBookingRef: ref,
      activeInvoiceNumber: invoiceNumber,
    });
    setGlobalState({ selectedDocuments: [] });
    viewPNRAmplitudeEvent(ref); // amplitude event
  };

  const isActive = activeBookingRef === bookingRef && activeInvoiceNumber === invoiceNumber;
  const cardBackground = isActive ? 'Card-Container-Active' : 'Card-Container';
  const color = {
    backgroundColor: isActive ? '#f7f8f9' : '#ffffff',
    cursor: 'pointer',
  };
  const tripNameStyle = isActive ? 'Trip-Name-Selected' : 'Trip-name';
  const dateStyle = isActive ? 'Departure-Date-Selected' : 'Departure-Date';
  const arrowStyle = isActive ? 'Arrow-Selected' : 'Arrow';
  const monthMap = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  const formateDate = () => date && `${date.substr(0, 4)}-${monthMap[parseInt(date.substr(5, 2), 10) - 1]}-${date.substr(8, 2)}`;

  if (!isMobile) {
    useEffect(() => {
      const bookingRefArr = trips && trips.slice(0, tripsToDisplay);
      setGlobalState({
        activeBookingRef: bookingRefArr[0].bookingRef,
        activeInvoiceNumber: bookingRefArr[0].invoicenumber,
      });
    }, []);
  }

  return (
    <div
      className="row"
      onClick={() => selectRef(bookingRef)}
            // onKeyDown={() => selectRef(bookingRef)}
      onKeyDown={() => console.log('key pressed')}
      style={color}
      role="button"
      tabIndex={0}
    >
      <div className={cardBackground}>
        <div className="Card-Info">
          <div className={tripNameStyle}>
            GBT
            {' '}
            {t('booking_ref')}
            {' '}
            {bookingRef}
          </div>
          <div className={dateStyle}>
            {t('invoice_date')}
            :
            {' '}
            {formateDate(date)}
          </div>
          {Array.isArray(name)
            ? (
              <div className="Multi-User-Name-Container">
                <MultipleUsers names={name} />
                <div className="Multi-User-Name">{name[0]}</div>
              </div>
            ) : (
              <div className="Traveler-Name">
                <div className="User-Name">
                  <ExpandableNameList names={name} />
                </div>
              </div>
            )}
        </div>
        <div className={arrowStyle} />
      </div>
    </div>
  );
}

InvoiceCard.propTypes = {
  activeBookingRef: PropTypes.string.isRequired,
  bookingRef: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
