import React from 'react';
import styles from './Spinner.module.css';

function Spinner() {
  return (
    <div className={styles.backDrop}>
      <div className={styles.spinner}>
        <div />
      </div>
    </div>
  );
}

export default Spinner;
