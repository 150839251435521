import React from 'react';
import PropTypes from 'prop-types';
import styles from './SidebarTab.module.css';

export default function SidebarTab({
  name, active, clickAction, tabText,
}) {
  const handleClick = () => {
    clickAction(name);
  };

  return (
    <div
      onClick={handleClick}
      // onKeyDown={handleClick}
      onKeyDown={() => console.log('clicked')}
      role="button"
      tabIndex={0}
      className={active ? 'Sidebar-Tab column' : 'Sidebar-Tab-Inactive column'}
    >
      {active ? <div className="Bar" /> : ''}
      <div
        className={active ? styles.SidebarTextActive : styles.SidebarTabTextInactive}
      >
        {tabText}
      </div>
    </div>
  );
}

SidebarTab.propTypes = {
  name: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  clickAction: PropTypes.func.isRequired,
  tabText: PropTypes.string.isRequired,
};
