import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { sendAmplitudeData } from '../../amplitude/Amplitude';
import { useGlobalState } from '../../hooks/StateHook';
import Spinner from '../modal/spinner/Spinner';
import { downloadDocument } from '../../apis/OneDDSAPI';
import styles from './DocumentCard.module.css';
import EmailListModal from '../modal/assistantEmail/EmailListModal';

export default function DocumentCard({
  selected, name, date, number, total, bucket, bucketKey, docid, currency, pnr, emails,
}) {
  const [globalState, setGlobalState] = useGlobalState();
  const [spinner, setSpinner] = useState(false);
  const { t } = useTranslation();
  const [emailListModal, setEmailListModal] = useState(false);
  const documentName = name.replace('Invoice_', '');

  const downloadFile = () => {
    setSpinner(true);
    const docsToDownload = [];

    docsToDownload.push({
      bucketname: bucket,
      bucketkey: bucketKey,
    });

    const downloadRequest = {
      documents: docsToDownload,
    };

    const processBase64Download = (base64Data, fileName, fileType) => {
      const byteCharacters = atob(base64Data.replace(/\s/g, '').replace(/['"]+/g, ''));
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i += 1) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const zip = new Blob([byteArray], { type: fileType });
      const win = window.open('/pdfFrame');
      win.pdfData = win.URL.createObjectURL(zip);
    };

    downloadDocument(downloadRequest.documents[0])
      .then((res) => res.text())
      .then((text) => {
        processBase64Download(text, `${name}.pdf`, 'application/pdf');
        setSpinner(false);
      }).catch((err) => {
        console.log(err);
        setSpinner(false);
      });
  };

  const selectDocAmplitudeEvent = () => {
    // Amplitude start //
    const event = 'Select document';
    const props = {
      Description: 'User selects or deselects individual invoice or "documents" option to select all PNR invoices',
      Source: 'ONEDDS',
      URL: window.location.hostname,
      HighValueInteraction: 'No',
      PNR: pnr,
      Tab: globalState.activeTab === 'trips' ? 'Recent Documents' : 'Search',
      CheckboxType: 'Individual Invoice',
    };
    sendAmplitudeData(event, props);
    // Amplitude end //
  };

  const toggleDocument = () => {
    const toAdd = {
      name,
      number,
      bucket,
      bucketKey,
      date,
      docid,
      pnr,
    };
    console.log(JSON.stringify(toAdd));
    if (globalState.selectedDocuments.find((d) => d.number === number)) {
      setGlobalState({
        selectedDocuments: globalState.selectedDocuments
          .filter((element) => element.number !== number),
      });
    } else {
      setGlobalState({ selectedDocuments: [...globalState.selectedDocuments, toAdd] });
      console.log(JSON.stringify(globalState.selectedDocuments));
    }
    selectDocAmplitudeEvent(); // amplitude event
  };
  const monthMap = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  const formateDate = () => date && `${date.substr(0, 4)}-${monthMap[parseInt(date.substr(5, 2), 10) - 1]}-${date.substr(8, 2)}`;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.documentCheckbox}>
          <input type="checkbox" checked={selected} className="Checkbox" onChange={toggleDocument} />
        </div>
        <div className={styles.documentContainer}>
          <div className={styles.documentDetailsContainer}>
            <div className={styles.documentInfoItem}>
              <div className={styles.label}>
                {t('document')}
              </div>
              <div className="column Centered Invoice">
                <div className={styles.display}>
                  <span role="button" tabIndex={0} onClick={downloadFile} onKeyDown={downloadFile} style={{ marginLeft: '0px', display: 'flex', alignItems: 'center' }}>
                    {documentName}
                    {' '}
                    <img style={{ marginLeft: '6px' }} alt="Invoice" src="https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/16x16/link_active@1x.png" />
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.documentInfoItem}>
              <div className={styles.label}>
                {t('invoice_number')}
              </div>
              <div className={styles.details}>
                {number}
              </div>
            </div>
            <div className={styles.documentInfoItem}>
              <div className={styles.label}>
                {t('invoice_date')}
              </div>
              <div className={styles.details}>
                {formateDate(date)}
              </div>
            </div>
          </div>
          <div className={styles.total}>
            <div className={styles.totalLabel}>{t('total')}</div>
            <div className={styles.totalText}>{`${total} ${currency}`}</div>
            <div className={styles.email_list}>
              <button
                type="submit"
                className={styles.button}
                onClick={() => setEmailListModal(true)}
                tabIndex={0}
              >
                {t('email_list')}
              </button>
            </div>
          </div>
        </div>
        {emailListModal && <EmailListModal emails={emails} documentname={documentName} closeModal={() => setEmailListModal(false)} />}
      </div>
      <div className="Element-Divider-Short-1px" />
      {spinner && <Spinner />}
    </>
  );
}

DocumentCard.propTypes = {
  selected: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
  bucket: PropTypes.string.isRequired,
  bucketKey: PropTypes.string.isRequired,
  docid: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
};
