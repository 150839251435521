/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InvoiceDetails from './InvoiceDetails';
import DocumentCard from './DocumentCard';
import { readGlobalState, writeGlobalState } from '../../hooks/StateHook';
import MobileNav from './MobileNav';
import InvoiceGroup from './InvoiceGroup';
import SuccessBanner from '../alerts/SuccessBanner';
import SearchResultBar from './SearchResultBar';
import ErrorBanner from '../alerts/ErrorBanner';
import Support from '../modal/support/Support';
import AssistantEmailModal from '../modal/assistantEmail/AssistantEmailModal';
import ViewMore from './ViewMore';
import { dateRangeSearch, searchTrips } from '../../apis/OneDDSAPI';

const convertToTime = (dateTime) => new Date(dateTime).getTime();
const lastName = (fullName) => {
  if (Array.isArray(fullName)) return fullName[0].split('/')[0];
  if (fullName && !Array.isArray(fullName)) return fullName.split('/')[0];
  return fullName;
};

const sortSearch = (sortValue, toSort) => {
  const innerSortObj = {};
  const outerSortObj = {};
  let sortArr;

  if (sortValue === 'Invoice Total (high to low)') {
    Object.entries(toSort).forEach((data) => { innerSortObj[data[0]] = data[1].sort((a, b) => b.totalamount - a.totalamount); });
    sortArr = Object.entries(innerSortObj).sort((a, b) => b[1][0].totalamount - a[1][0].totalamount);
  }

  if (sortValue === 'Invoice Total (low to high)') {
    Object.entries(toSort).forEach((data) => { innerSortObj[data[0]] = data[1].sort((a, b) => a.totalamount - b.totalamount); });
    sortArr = Object.entries(innerSortObj).sort((a, b) => a[1][0].totalamount - b[1][0].totalamount);
  }

  if (sortValue === 'Trip Date (oldest to newest)') {
    Object.entries(toSort).forEach((data) => { innerSortObj[data[0]] = data[1].sort((a, b) => convertToTime(a.departureDate) - convertToTime(b.departureDate)); });
    const filter1 = Object.entries(innerSortObj).filter((data) => 'departureDate' in data[1][0]).sort((a, b) => convertToTime(a[1][0].departureDate) - convertToTime(b[1][0].departureDate));
    const filter2 = Object.entries(innerSortObj).filter((data) => !('departureDate' in data[1][0]));
    sortArr = [...filter1, ...filter2];
  }

  if (sortValue === 'Trip Date (newest to oldest)') {
    Object.entries(toSort).forEach((data) => { innerSortObj[data[0]] = data[1].sort((a, b) => convertToTime(b.departureDate) - convertToTime(a.departureDate)); });
    const filter1 = Object.entries(innerSortObj).filter((data) => 'departureDate' in data[1][0]).sort((a, b) => convertToTime(b[1][0].departureDate) - convertToTime(a[1][0].departureDate));
    const filter2 = Object.entries(innerSortObj).filter((data) => !('departureDate' in data[1][0]));
    sortArr = [...filter1, ...filter2];
  }

  if (sortValue === 'Last Name (A to Z)') {
    Object.entries(toSort).forEach((data) => { innerSortObj[data[0]] = data[1].sort((a, b) => lastName(a.travelerName).localeCompare(lastName(b.travelerName))); });
    sortArr = Object.entries(innerSortObj).sort((a, b) => lastName(a[1][0].travelerName).localeCompare(lastName(b[1][0].travelerName)));
  }

  if (sortValue === 'Last Name (Z to A)') {
    Object.entries(toSort).forEach((data) => { innerSortObj[data[0]] = data[1].sort((a, b) => lastName(b.travelerName).localeCompare(lastName(a.travelerName))); });
    sortArr = Object.entries(innerSortObj).sort((a, b) => lastName(b[1][0].travelerName).localeCompare(lastName(a[1][0].travelerName)));
  }
  /* eslint-disable-next-line */
  sortArr.forEach((data) => { outerSortObj[' ' + data[0]] = data[1]; });
  return outerSortObj;
};

export default function MainContent() {
  const [globalState] = readGlobalState(); // NOSONAR both are required to access global state
  const [setGlobalState] = writeGlobalState();
  const [supportmodal, setSupportModal] = useState(false);
  const [assistantEmailmodal, setAssistantEmailmodal] = useState(false);

  const PAGE_SIZE = 100;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const mapFunc = (res) => {
    const map = {};
    res.forEach((invoice) => {
      const existingList = map[invoice.pnr];
      if (!existingList) {
        map[invoice.pnr] = [invoice];
      } else {
        existingList.push(invoice);
      }
    });
    return map;
  }

  const viewMore = async () => {
    try {
      let response;
      if (globalState.search_date.length) {
        response = await dateRangeSearch(globalState.search_date[0], globalState.search_date[1], globalState.search_after)

      }
      else {
        response = await searchTrips(globalState.search_term, undefined, globalState.search_after)
      }
      const data = await response.json()
      const mapdata = mapFunc(data.documents)

      setGlobalState({
        search_after: data.search_after,
        searchResultRaw: [...globalState.searchResultRaw, ...data.documents],
        docs: [...globalState.docs, mapdata],
        remDocs: globalState.remDocs - PAGE_SIZE
      });
    } catch (error) {
      console.log(`Error in viewmore function, ${error}`)
    }
  }

  let name = '';
  let bookingRef = '';
  let date = '';
  let emails = null;
  let invoices = [];
  let singleInvoice;

  if (globalState.rawDocuments && globalState.rawDocuments.length > 0 && globalState.activeBookingRef !== '') {
    singleInvoice = globalState.rawDocuments.find((t) => t.pnr === globalState.activeBookingRef);
    const matchedTrip = globalState.rawDocuments
      .find((t) => t.pnr === globalState.activeBookingRef && t.invoicenumber === globalState.activeInvoiceNumber);
    if (matchedTrip) {
      name = matchedTrip.travelerName;
      date = matchedTrip.departureDate;
      bookingRef = matchedTrip.pnr;

      if (matchedTrip.emails) {
        emails = (matchedTrip.emails.map((email) => email.email));
      } else {
        emails = [];
      }

      invoices = [{
        name: `Invoice_${matchedTrip.clientid}_${matchedTrip.invoicenumber.substr(-4)}`,
        number: matchedTrip.invoicenumber,
        date: matchedTrip.invoiceIssueDate,
        bucket: matchedTrip.bucketname,
        bucketKey: matchedTrip.bucketkey,
        docid: matchedTrip.docid,
        total: matchedTrip.totalamount,
        currency: matchedTrip.currency,
        pnr: matchedTrip.pnr,
        emails: emails
      }];
    }
  }


  let content;

  if (globalState.mainResult) {
    let tempObject = [globalState.mainResult, ...globalState.docs]

    if (globalState.sort) {
      let mapdata = mapFunc(globalState.searchResultRaw)
      tempObject = [sortSearch(globalState.sort, mapdata)];
    }
    content = (
      <>
        <SearchResultBar />
        <div className="scrollable">
          {tempObject.map((item) => (<InvoiceGroup invoiceMap={item} />))}
          {(globalState.clearFilter && globalState.remDocs > 0) && <ViewMore viewMore={viewMore} />}
        </div>
      </>
    );
  } else {
    content = (
      <div className="Documents-List-Background">
        <InvoiceDetails
          selectedDocuments={globalState.selectedDocuments}
          name={name}
          emails={emails}
          bookingRef={bookingRef}
          date={date}
          invoices={[singleInvoice]}
          pnr={bookingRef}
          names={name}
        />
        <div>
          <div className="Element-Divider-1px" />
          {invoices.map((invoice) => (
            <DocumentCard
              selected={globalState.selectedDocuments
                .find((o) => o.number === invoice.number) !== undefined}
              name={invoice.name}
              date={invoice.date}
              number={invoice.number}
              total={invoice.total}
              bucket={invoice.bucket}
              bucketKey={invoice.bucketKey}
              docid={invoice.docid}
              currency={invoice.currency}
              pnr={invoice.pnr}
              emails={invoice.emails}
            />
          ))}
        </div>
      </div>
    );
  }
  const { t } = useTranslation();

  return (
    <div className="column">
      <div className="Banner-Gradient">
        <div className="Title-Container">
          <div className="support-header">
            <a onClick={() => setSupportModal(true)} className="opentextURL">{t('cust_support')}</a>
            {<a onClick={() => setAssistantEmailmodal(true)} className="opentextURL">{t('manage_assistant')}</a>}
          </div>
          <div className="Document-View-Title">{t('documents')}</div>
          {/* <div className="integration-text">
            {t('redirect_msg5')}
            {' '}
            <a className="opentextURL2" href="https://invoicing-amexgbt.opentext.com/amexgbt/page/login" target="_blank" rel="noreferrer">{t('redirect_msg6')}</a>
            {t('redirect_msg7')}
          </div> */}
        </div>
      </div>
      <MobileNav />
      {globalState.emailSuccess ? <SuccessBanner message={t('email_success')} /> : ''}
      {globalState.emailFailure ? <ErrorBanner message={t('email_failure')} /> : ''}
      {content}
      {supportmodal && <Support closeModal={() => setSupportModal(false)} />}
      {assistantEmailmodal && <AssistantEmailModal closeModal={() => setAssistantEmailmodal(false)} />}
    </div>
  );
}