import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { sendAmplitudeData } from '../../amplitude/Amplitude';
import styles from './SortDropdown.module.css';
import SortListItem from './SortListItem';
import { writeGlobalState, readGlobalState } from '../../hooks/StateHook';

export default function SortDropdown({ show, close }) {
  if (!show) {
    return null;
  }
  const [setGlobalState] = writeGlobalState();
  const [globalState] = readGlobalState();
  const [selectedItem, setSelectedItem] = useState('');
  const { t } = useTranslation();

  const sortAmplitudeEvent = (e) => {
    const event = e;
    const sortEventProps = {
      Description: 'User clicks on "Sort" within sort menu',
      Source: 'ONEDDS',
      URL: window.location.hostname,
      HighValueInteraction: 'No',
      SortType: selectedItem,
      Tab: globalState.activeTab === 'trips' ? 'Recent Documents' : 'Search',
    };
    const clearSortEventProps = {
      Description: 'User clicks on "Clear Sort" within sort menu',
      Source: 'ONEDDS',
      URL: window.location.hostname,
      HighValueInteraction: 'No',
      Tab: globalState.activeTab === 'trips' ? 'Recent Documents' : 'Search',
    };
    sendAmplitudeData(event, e === 'Sort' ? sortEventProps : clearSortEventProps);
  };

  const clearSort = () => {
    setSelectedItem('');
    setGlobalState({ sort: undefined, selectedSort: '' });
    sortAmplitudeEvent('Clear Sort'); // clear sort amplitude event
  };

  const selectItem = (item) => {
    setSelectedItem(item);
    setGlobalState({ selectedSort: item });
  };

  const doSort = () => {
    setGlobalState({
      sort: selectedItem,
    });
    close();
    sortAmplitudeEvent('Sort'); // sort amplitude event
  };

  const listItems = ['Trip Date (newest to oldest)', 'Trip Date (oldest to newest)', 'Invoice Total (low to high)', 'Invoice Total (high to low)', 'Last Name (A to Z)', 'Last Name (Z to A)'];
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerText}>{t('sort_search_results_by')}</div>
      </div>
      <div className={styles.divider} />
      <div className={styles.list}>
        {listItems.map((item) => (
          <SortListItem
            clickAction={() => selectItem(item)}
            text={item}
            selected={item === selectedItem}
          />
        ))}
      </div>
      <div className={styles.divider} />
      <div className={styles.footer}>
        <div
          role="button"
          onClick={clearSort}
          // onKeyDown={clearSort}
          onKeyDown={() => { console.log('Clear sort'); }}
          tabIndex={0}
          className={styles.clearText}
        >
          {t('clear_sort')}
        </div>
        <div className={styles.footerRight}>
          <div
            role="button"
            tabIndex={0}
            onClick={doSort}
            // onKeyDown={doSort}
            onKeyDown={() => { console.log('apply sort'); }}
            className={styles.button}
          >
            <div className={styles.buttonText}>
              {t('sort')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SortDropdown.propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};
