/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '../../../hooks/StateHook';
import { sendAmplitudeData } from '../../../amplitude/Amplitude';
import Minmax from './Minmax';
import Dropdown from './Dropdown';
import FilterTopBar from './FilterTopBar';
import Country from './Country'
import styles from './FilterContent.module.css';
import TravelType from './TravelType';
import DocType from './DocType';

export default function FilterContent() {
  const { t } = useTranslation();
  const [countryArr, setCountryArr] = useState([]);
  const [travelTypeArr, setTravelTypeArr] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [amount, setAmount] = useState(false);
  const [allGlobals, setGlobalState] = useGlobalState();

  const filterByCountry = (arr, condition) => arr
    .filter((item) => condition.includes(item.countrycode));
  const filterByTravelType = (arr, condition) => arr
    .filter((data) => data.traveltypes.some((item) => condition.includes(item)));

  const filterByAmount = (arr, min, max) => {
    if (!min) return arr.filter((data) => data.totalamount <= Number(max));
    if (!max) return arr.filter((data) => data.totalamount >= Number(min));
    return arr.filter((data) => data.totalamount >= Number(min) && data.totalamount <= Number(max));
  };

  const filterByTransactionType = (arr, condition) => arr
    .filter((item) => condition.includes(item.transactiontype));

  const filterByDocType = (arr, doctype) => arr
    .filter((item) => item.transactiontype === doctype)

  // for array and string condition check currently
  const isNotUndefinedOrEmpty = (value) => {
    if (typeof value !== 'undefined') {
      if (value.constructor === Array && value.length !== 0) { return value; }
      if (typeof value === 'string' && value !== '') { return value; }
      return false;
    }
    return false;
  };

  const btnVisible = () => {
    const random = new Set();
    if (allGlobals.searchResultRaw.length !== 0) {
      allGlobals.searchResultRaw.forEach((data) => {
        if (data.countrycode) random.add(data.countrycode);
        else if (data.totalamount) random.add(true);
        else if (data.traveltypes.length !== 0) {
          data.traveltypes.forEach((item) => {
            random.add(item);
          });
        }
      });
    }
    return random.size;
  };

  const mapFunc = (res) => {
    const map = {};
    res.forEach((invoice) => {
      const existingList = map[invoice.pnr];
      if (!existingList) {
        map[invoice.pnr] = [invoice];
      } else {
        existingList.push(invoice);
      }
    });
    return map;
  }

  /* istanbul ignore next */
  useEffect(() => {
    console.log('@@@@@@@@@@trigger@@@@@@@@@@@@')
    // initialising state feilds - START
    if (allGlobals.searchResultRaw.length !== 0) {
      const countrySet = new Set();
      const documentTypesSet = new Set();
      const travelTypeSet = new Set();
      const amountSetBoolean = new Set();
      const transactionTypeSet = new Set();

      allGlobals.searchResultRaw.forEach((data) => {
        if (data.countrycode) countrySet.add(data.countrycode);
        if (data.invoicenumber) documentTypesSet.add(true);
        if (data.totalamount) amountSetBoolean.add(true);
        if (data.traveltypes.length !== 0) {
          data.traveltypes.forEach((item) => {
            travelTypeSet.add(item);
          });
        }
        if (data.transactiontype) transactionTypeSet.add(data.transactiontype);
      });

      setCountryArr([...countrySet]);
      setTravelTypeArr([...travelTypeSet]);

      if ([...amountSetBoolean].includes(true)) setAmount(true);
      if ([...documentTypesSet].length === 1 && [...documentTypesSet][0] === true) setDocumentTypes([...transactionTypeSet]);
    } else {
      setCountryArr([]);
      setTravelTypeArr([]);
      setAmount(false);
      setDocumentTypes([]);
      // setGlobalState({ selectedDocuments: [] });
    }

    // initialising state feilds - END
  }, [allGlobals.searchResultRaw]);

  // on apply filter button click - START
  const applyFilters = () => {
    const obj = {
      countries: isNotUndefinedOrEmpty(allGlobals.countryFilterArr),
      minAmt: isNotUndefinedOrEmpty(allGlobals.amountMinMaxArr[0]),
      maxAmt: isNotUndefinedOrEmpty(allGlobals.amountMinMaxArr[1]),
      travelTypes: isNotUndefinedOrEmpty(allGlobals.travelTypeArr),
      transactionTypes: isNotUndefinedOrEmpty(allGlobals.documentTypes),
    };

    let arr = [];
    let flag = true

    if (obj.countries || obj.travelTypes || obj.minAmt || obj.maxAmt || obj.transactionTypes) {
      // filter by country
      if (obj.countries) {
        arr = filterByCountry(allGlobals.searchResultRaw, obj.countries);
        if (!arr.length) flag = false
      }
      console.log('Country Filter 1', arr, obj, flag)

      // filter by travel type
      if (obj.travelTypes && flag) {
        if (arr.length !== 0) {
          arr = filterByTravelType(arr, obj.travelTypes);
          if (!arr.length) flag = false
        }
        else arr = filterByTravelType(allGlobals.searchResultRaw, obj.travelTypes);
      }
      console.log('Travel type Filter 2', arr, obj, flag)

      // filter by amount
      if ((obj.minAmt || obj.maxAmt) && flag) {
        if (arr.length !== 0) {
          arr = filterByAmount(arr, obj.minAmt, obj.maxAmt);
          if (!arr.length) flag = false
        }
        else arr = filterByAmount(allGlobals.searchResultRaw, obj.minAmt, obj.maxAmt);
      }
      console.log('Amount Filter 3', arr, obj, flag)

      // filter by document type
      if (obj.transactionTypes && flag) {
        if (arr.length !== 0) {
          arr = filterByDocType(arr, obj.transactionTypes)
          if (!arr.length) flag = false
        }
        else arr = filterByDocType(allGlobals.searchResultRaw, obj.transactionTypes);
      }
      console.log('Doc type Filter 4', arr, obj, flag)

      // storing data in global variables and updating main component
      if (arr) {
        const map = {};
        if (arr.length !== 0) {
          arr.forEach((invoice) => {
            const existingList = map[invoice.pnr];
            if (!existingList) map[invoice.pnr] = [invoice];
            else existingList.push(invoice);
          });
          setGlobalState({ mainResult: map, selectedDocuments: [], refineSearch: false, docs: [] });
          filterAmplitudeEvent('Apply Filters');
        } else {
          setGlobalState({ mainResult: map, selectedDocuments: [], refineSearch: false, docs: [] });
          filterAmplitudeEvent('Apply Filters');
        }
      }
    } else {
      setGlobalState({ mainResult: allGlobals.searchResult, selectedDocuments: [] });
      filterAmplitudeEvent('Clear Filters');
    }
  };
  // on apply filter button click - END 

  const clearFiltersFunc = (fireAmplitudeEvent) => {
    // setGlobalState({ mainResult: allGlobals.searchResult, selectedDocuments: [] });
    setGlobalState({ mainResult: mapFunc(allGlobals.searchResultRaw), selectedDocuments: [] });
    fireAmplitudeEvent && filterAmplitudeEvent('Clear Filters');
  }

  const filterAmplitudeEvent = (eventType) => {
    // Amplitude start //
    const event = eventType;
    const applyFilterProps = {
      Description: 'User clicks on "Apply Filters" button',
      Source: 'ONEDDS',
      URL: window.location.hostname,
      HighValueInteraction: 'No',
      Tab: allGlobals.activeTab === 'trips' ? 'Recent Documents' : 'Search',
      MinAmount: allGlobals.amountMinMaxArr[0] ? allGlobals.amountMinMaxArr[0] : '',
      MaxAmount: allGlobals.amountMinMaxArr[1] ? allGlobals.amountMinMaxArr[1] : '',
      CountryOfBilling: allGlobals.countryFilterArr,
      TravelType: allGlobals.travelTypeArr,
    };
    const clearfilterProps = {
      Description: 'User clicks on "Clear Filters" button',
      Source: 'ONEDDS',
      URL: window.location.hostname,
      HighValueInteraction: 'No',
      Tab: allGlobals.activeTab === 'trips' ? 'Recent Documents' : 'Search',
    };
    sendAmplitudeData(event, eventType === 'Apply Filters' ? applyFilterProps : clearfilterProps);
    // Amplitude end //  
  }

  // convert array to object having key as array and values as boolean false
  const converter = (arr) => {
    const obj = {};
    arr.forEach((data) => { obj[data] = false; });
    //console.log('conerter', obj);
    return JSON.stringify(obj);
  };

  return (
    allGlobals.filterToggle && ( // filter comp visible only after search btn press
      <div className={styles.scroller}>
        {(amount || countryArr.length !== 0 || travelTypeArr.length !== 0
          || documentTypes.length !== 0 || btnVisible() !== 0) && (
            <FilterTopBar
              // applyFilters={applyFilters}
              clearFiltersFunc={clearFiltersFunc}
            />
          )}
        {amount && ( // comps visible only if state values not null/empty
          <Minmax
            title={t('amount')}
            minLabel={t('min_amount')}
            maxLabel={t('max_amount')}
          />
        )}
        {countryArr.length !== 0 && (
          <Country values={countryArr} />
        )}

        {travelTypeArr.length !== 0 && (
          <TravelType values={travelTypeArr} />
        )}
        {/* {documentTypes.length !== 0 && (
          <Dropdown
            title={t('doc_type')}
            values={documentTypes}
          />
        )} */}
        {documentTypes.length !== 0 && (
          <DocType
            title={t('doc_type')}
            values={documentTypes}
          />
        )}

        {(amount || countryArr.length !== 0 || travelTypeArr.length !== 0
          || btnVisible() !== 0) && (
            <div
              role="button"
              tabIndex={0}
              className={styles.filterButton}
              onClick={applyFilters}
              // onKeyDown={applyFilters}
              onKeyDown={() => console.log('Apply filters')}
            >
              <div className={styles.btnLabel}>{t('apply_filters')}</div>
            </div>
          )}
      </div>
    )
  );
}
