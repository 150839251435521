import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { downloadDocument } from '../../apis/OneDDSAPI';
import { useGlobalState } from '../../hooks/StateHook';
import { sendAmplitudeData } from '../../amplitude/Amplitude';
import SendEmailModal from '../modal/email/SendEmailModal';
import Spinner from '../modal/spinner/Spinner';

export default function ExportControls({ focusDownload, emails, focusEmail, pnr }) {
  const [renderTooltip, setRenderTooltip] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const { t } = useTranslation();
  const [renderModal, setRenderModal] = useState(false);
  const [globalState, setGlobalState] = useGlobalState();
  const textClass = focusDownload ? 'View-Map-Link-Active' : 'View-Map-Link';
  const textClass2 = focusEmail ? 'View-Map-Link-Active' : 'View-Map-Link';
  const downloadImageCDN = focusDownload ? 'https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/16x16/download_active@1x.png'
    : 'https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/16x16/download_disabled@1x.png';
  const emailImageCDN = focusEmail ? 'https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/16x16/email_active@1x.png'
    : 'https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/16x16/email_disabled@1x.png';

  const showTooltip = () => {
    setRenderTooltip(true);
  };
  const hideTooltip = () => {
    setRenderTooltip(false);
  };
  const emailModal = () => {
    if (focusEmail) {
      setRenderModal(true);
    }
    // Amplitude start //
    const event = 'Initiate email PDF';
    const desc = globalState.activeTab === 'trips'
      ? 'User clicks on "Email" option on Recent Documents tab'
      : 'User clicks on "Email" option on Search Documents tab';
    const props = {
      Description: desc,
      Source: 'ONEDDS',
      URL: window.location.hostname,
      HighValueInteraction: 'No',
      PNR: pnr,
      Tab: globalState.activeTab === 'trips' ? 'Recent Documents' : 'Search',
      NoDocumentSelectedTooltip: focusEmail ? 'No' : 'Yes',
    };
    sendAmplitudeData(event, props);
    // Amplitude end //
  };

  const tooltipper = () => {
    if (globalState.tooltipShow) {
      showTooltip();
      setGlobalState({ tooltipShow: !globalState.tooltipShow });
    } else {
      hideTooltip();
      setGlobalState({ tooltipShow: !globalState.tooltipShow });
    }
  };

  const downloadAmplitudeEvent = (success) => {
    // Amplitude start //
    const event = 'Initiate document download';
    const props = {
      Description: 'User clicks on "Download" option',
      Source: 'ONEDDS',
      URL: window.location.hostname,
      HighValueInteraction: 'No',
      Tab: globalState.activeTab === 'trips' ? 'Recent Documents' : 'Search',
      Success: success ? 'Yes' : 'No',
    };
    sendAmplitudeData(event, props);
    // Amplitude end //
  };

  const downloadFiles = () => {
    if (focusDownload) {
      setSpinner(true);
      const docsToDownload = [];

      for (let i = 0; i < globalState.selectedDocuments.length; i += 1) {
        console.log(JSON.stringify(globalState.selectedDocuments[i]));
        docsToDownload.push({
          bucketname: globalState.selectedDocuments[i].bucket,
          bucketkey: globalState.selectedDocuments[i].bucketKey,
          name: globalState.selectedDocuments[i].name,
        });
      }

      const downloadRequest = {
        documents: docsToDownload,
      };
      const processBase64Download = (base64Data, fileName, fileType) => {
        const byteCharacters = atob(base64Data.replace(/\s/g, '').replace(/['"]+/g, ''));
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i += 1) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const zip = new Blob([byteArray], { type: fileType });
        const href = window.URL.createObjectURL(zip);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      };

      console.log(JSON.stringify(downloadRequest));
      if (docsToDownload.length > 1) {
        downloadDocument(downloadRequest)
          .then((res) => res.text())
          .then((text) => {
            processBase64Download(text, 'invoices.zip', 'application/zip');
            setSpinner(false);
            downloadAmplitudeEvent(true); // amplitude event
          }).catch((err) => {
            console.log(err);
            setSpinner(false);
            downloadAmplitudeEvent(false); // amplitude event
          });
      } else if (docsToDownload.length === 1) {
        downloadDocument(downloadRequest.documents[0])
          .then((res) => res.text())
          .then((text) => {
            processBase64Download(text, `${downloadRequest.documents[0].name}.pdf`, 'application/pdf');
            setSpinner(false);
            downloadAmplitudeEvent(true); // amplitude event
          }).catch((err) => {
            console.log(err);
            setSpinner(false);
            downloadAmplitudeEvent(false); // amplitude event
          });
      }
    }
  };
  return (
    <>
      {!focusDownload && renderTooltip ? (
        <div className="tooltipContainer"><div className="Tooltip">{t('tooltip_text')}</div></div>) : ''}
      <div role="button" onKeyDown={tooltipper} tabIndex={0} onClick={tooltipper} onMouseEnter={showTooltip} onMouseLeave={hideTooltip} className="Export-Controls-Background row">
        <div
          onClick={downloadFiles}
          onKeyDown={() => { console.log('Download files'); }}
          role="button"
          tabIndex={0}
          className={textClass}
        >
          <div style={{ verticalAlign: 'center', display: 'flex', alignItems: 'center' }}>
            <img style={{ marginRight: '10px' }} src={downloadImageCDN} alt="download" />
            {t('download')}
          </div>
        </div>
        <div
          onClick={emailModal}
          onKeyDown={() => console.log('email modal open')}
          role="button"
          tabIndex={0}
          className={textClass2}
        >
          <div style={{ verticalAlign: 'center', display: 'flex', width: '70px', alignItems: 'center' }}>
            <img style={{ marginRight: '10px' }} src={emailImageCDN} alt="Email Icon" />
            {t('email')}
          </div>
        </div>
        <SendEmailModal
          show={renderModal}
          closeModal={() => setRenderModal(false)}
          invoices={globalState.selectedDocuments.filter((data) => data.pnr === pnr)}
          emailArr={emails}
        />
      </div>
      {spinner && <Spinner />}
    </>
  );
}

ExportControls.propTypes = {
  focusDownload: PropTypes.bool.isRequired,
  focusEmail: PropTypes.bool.isRequired,
};
