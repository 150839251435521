import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './LoadingCard.module.css';

export default function LoadingCard() {
  const { t } = useTranslation();
  return (
    <div className="Card-Container">
      <div className="Card-Info">
        <div className={styles.header}>{t('load_trips')}</div>
        <div className={styles.text}>{t('please_wait')}</div>
      </div>
    </div>
  );
}
