import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function ViewMoreCard({ viewing, total, clickAction }) {
  const { t } = useTranslation();
  return (
    <div className="Card-Container">
      <div style={{ width: '100%' }} className="Card-Info">
        <div className="View-More-Card">
          <div className="Remaining-Trips">
            <div className="Remaining-Trips-Text">
              {viewing}
              {' '}
              {t('of')}
              {' '}
              {total}
              {' '}
              {t('documents')}
            </div>
          </div>
          {viewing >= total ? '' : (
            <div
              onClick={clickAction}
              onKeyDown={() => console.log('clicked')}
              role="button"
              tabIndex={0}
              className="View-More-Button"
            >
              {t('view_more')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

ViewMoreCard.propTypes = {
  viewing: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  clickAction: PropTypes.func.isRequired,
};
