import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './DatePicker.module.css';

export default function DatePicker({ selectStartDate, selectEndDate, dateToSet, start, end }) {
  const daysMap = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const monthMap = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const [stateMonth, setStateMonth] = useState(new Date().getMonth());
  const [stateYear, setStateYear] = useState(new Date().getFullYear());
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const [dateToSelect, setDateToSelect] = useState(dateToSet);

  const getNumberOfDays = (year, month) => 40 - new Date(year, month, 40).getDate();

  const getDayDetails = (args) => {
    const date = args.index - args.firstDay;
    const day = args.index % 7;
    let prevMonth = args.month - 1;
    let prevYear = args.year;
    if (prevMonth < 0) {
      prevMonth = 11;
      prevYear -= 1;
    }
    const prevMonthNumberOfDays = getNumberOfDays(prevYear, prevMonth);
    const alteredDate = (date < 0 ? prevMonthNumberOfDays + date : date % args.numberOfDays) + 1;
    let month;
    if (date < 0) {
      month = -1;
    } else if (date >= args.numberOfDays) {
      month = 1;
    } else {
      month = 0;
    }
    const timestamp = new Date(args.year, args.month + month, alteredDate).getTime();
    return {
      date: alteredDate,
      day,
      month,
      fullMonth: monthMap[args.month].toUpperCase().substr(0, 3),
      monthNumeric: args.month + 1,
      timestamp,
      // dateString: new Date(args.year, args.month + month, alteredDate).toISOString(),
      dateString: new Date(Date.UTC(args.year, args.month, alteredDate)).toISOString(),
      year: args.year,
      dayString: daysMap[day],
    };
  };

  const getMonthDetails = (year, month) => {
    const firstDay = (new Date(year, month)).getDay();
    const numberOfDays = getNumberOfDays(year, month);
    const monthArray = [];
    const rows = 6;
    let currentDay = null;
    let index = 0;
    const cols = 7;

    for (let row = 0; row < rows; row += 1) {
      for (let col = 0; col < cols; col += 1) {
        currentDay = getDayDetails({
          index,
          numberOfDays,
          firstDay,
          year,
          month,
        });
        monthArray.push(currentDay);
        index += 1;
      }
    }
    return monthArray;
  };

  const result = getMonthDetails(stateYear, stateMonth);

  const [monthDetails, setMonthDetails] = useState(result);

  const setDate = (date) => {
    console.log('Setting Date');
    if (dateToSelect === 'start') {
      setStartDate(date);
      selectStartDate(date);
      setDateToSelect('end');
      if (endDate && date.timestamp > endDate.timestamp) {
        setEndDate(undefined);
        selectEndDate(undefined);
      }
      return;
    }
    if (dateToSelect === 'end') {
      setEndDate(date);
      selectEndDate(date);
      setDateToSelect('start');
      if (startDate && date.timestamp < startDate.timestamp) {
        setStartDate(undefined);
        selectStartDate(undefined);
      }
      return;
    }
    if (startDate === undefined || date.timestamp < startDate.timestamp || (endDate)) {
      setStartDate(date);
      setEndDate(undefined);
      selectStartDate(date);
      selectEndDate(undefined);
      console.log(`Set start date to ${date}`);
    } else {
      setEndDate(date);
      selectEndDate(date);
      console.log(`Set end date to ${date}`);
    }
  };

  const changeMonth = (offset) => {
    let year = stateYear;
    let month = stateMonth + offset;
    if (month === -1) {
      month = 11;
      year -= 1;
    } else if (month === 12) {
      month = 0;
      year += 1;
    }
    setStateYear(year);
    setStateMonth(month);
    setMonthDetails(getMonthDetails(year, month));
  };

  const dayElements = monthDetails.map((day) => {
    let isSelected = false;
    let inRange = false;
    const today = day.date === new Date().getDate()
        && day.monthNumeric === new Date().getMonth() + 1
        && day.year === new Date().getFullYear()
        && !(day.month < 0 || day.month === 1);
    if (startDate || endDate) {
      const isStartDate = startDate
          && (day.timestamp === startDate.timestamp && day.month === startDate.month);
      const isEndDate = endDate
          && (day.timestamp === endDate.timestamp && day.month === endDate.month);
      isSelected = isStartDate || isEndDate;
      inRange = (startDate
          && endDate
          && day.timestamp > startDate.timestamp
          && day.timestamp < endDate.timestamp);
    }
    let cssClass = '';
    if (inRange) {
      cssClass = styles.range;
    } else if (today) {
      cssClass = styles.today;
    }
    return (
      <td aria-selected={isSelected}>
        <button
          type="button"
          className={cssClass}
          onClick={() => setDate(day)}
          disabled={day.month < 0 || day.month === 1}
          aria-describedby="depart-label"
          aria-label="Wednesday, August 16, 2018"
        >
          {day.date}
        </button>
      </td>
    );
  });

  const weeks = [];

  for (let i = 0; i < dayElements.length; i += 7) {
    weeks.push(dayElements.slice(i, i + 7));
  }

  return (
    <div className={[styles.datePicker, styles.calendar, styles.range, styles.border].join(' ')}>
      <button type="button" onClick={() => changeMonth(-1)} className={[styles.dlsGlyphLeft, styles.glyph, styles.glyphLg].join(' ')} aria-label="Previous Month" />
      <button type="button" onClick={() => changeMonth(1)} className={[styles.dlsGlyphRight, styles.glyph, styles.glyphLg].join(' ')} aria-label="Next Month" />
      <table className={styles.table}>
        <caption className={[styles.heading, styles.gray].join(' ')}>
          {monthMap[stateMonth]}
          {' '}
          {stateYear}
        </caption>
        <thead>
          <tr>
            <th><abbr aria-label="Sunday">S</abbr></th>
            <th><abbr aria-label="Monday">M</abbr></th>
            <th><abbr aria-label="Tuesday">T</abbr></th>
            <th><abbr aria-label="Wednesday">W</abbr></th>
            <th><abbr aria-label="Thursday">T</abbr></th>
            <th><abbr aria-label="Friday">F</abbr></th>
            <th><abbr aria-label="Saturday">S</abbr></th>
          </tr>
        </thead>
        <tbody>
          {weeks.map((week) => (
            <tr>
              {week.map((day) => (day
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

DatePicker.propTypes = {
  selectStartDate: PropTypes.func.isRequired,
  selectEndDate: PropTypes.func.isRequired,

};
