import React from 'react';
import PropTypes from 'prop-types';
import styles from './InvoiceListItem.module.css';
import { sendAmplitudeData } from '../../../amplitude/Amplitude';
import { useGlobalState } from '../../../hooks/StateHook';

export default function InvoiceListItem({ invoice }) {
  const [globalState, setGlobalState] = useGlobalState();

  const removeSelectedDocument = () => {
    if (globalState.selectedDocuments.find((d) => d.number === invoice.number)) {
      setGlobalState({
        selectedDocuments: globalState.selectedDocuments
          .filter((element) => element.number !== invoice.number),
      });
      // Amplitude start //
      const event = 'Select document to email';
      const props = {
        Description: 'User selects or deselects individual document within "Email document" pop up',
        Source: 'ONEDDS',
        URL: window.location.hostname,
        HighValueInteraction: 'No',
        PNR: invoice.pnr,
        Tab: globalState.activeTab === 'trips' ? 'Recent Documents' : 'Search',
        InvoiceNumber: invoice.number,
      };
      sendAmplitudeData(event, props);
      // Amplitude end //
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.name}>{invoice.name}</div>
      <div className={styles.date}>{invoice.date}</div>
      <div
        role="button"
        tabIndex={0}
        onClick={removeSelectedDocument}
        // onKeyDown={removeSelectedDocument}
        onKeyDown={() => { console.log('Remove selected document'); }}
      >
        <img className={styles.close} src="https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/12x12/close_active@1x.png" alt="close" />
      </div>
    </div>
  );
}

InvoiceListItem.propTypes = {
  invoice: PropTypes.objectOf({
    name: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
  }).isRequired,
};
