import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Footer.module.css';
// import PropTypes from 'prop-types';

export default function Footer() {
  const { t } = useTranslation();
  return (
    <div className={styles.footer_container}>
      <div className={styles.links}>
        <a href="https://www.amexglobalbusinesstravel.com/terms-service/" target="_blank" rel="noreferrer">{t('term_of_service')}</a>
        <span className={styles.separator}>|</span>
        <a href="http://privacy.amexgbt.com/" target="_blank" rel="noreferrer">{t('footer1')}</a>
        <span className={styles.separator}>|</span>
        <a className={styles.lastLink} href="https://www.amexglobalbusinesstravel.com/about-us" target="_blank" rel="noreferrer">{t('footer2')}</a>
      </div>
      <div className={styles.line2}>{t('footer3')}</div>
      <div className={styles.line3}>
        {t('footer_body')}
      </div>
    </div>
  );
}

// Footer.propTypes = {
//   message: PropTypes.string.isRequired,
// };
