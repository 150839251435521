import React from 'react';

import {
  Route, useHistory, Switch,
} from 'react-router-dom';
import {
  Security, LoginCallback, SecureRoute,
} from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import { getConfigForEnvironment } from './config/env-config';

import SessionTimeoutWarning from './hoc/timeout/SessionTimeoutWarning';

import App from './App';
import TopUserDashboard from './components/topusers/TopUserDashboard';
import PageNotFound from './components/error/PageNotFound';
import InactivitySignout from './components/error/InactivitySignout';
import PDFFrame from './components/main/PDFFrame';

// save referrer value -START
const data = sessionStorage.getItem('ref');
if (!(document.referrer) && data) {
  const arr = JSON.parse(data);
  if (arr.length === 1 && arr[0]) sessionStorage.setItem('ref', JSON.stringify([...arr, '']));
  else sessionStorage.setItem('ref', JSON.stringify([document.referrer]));
} else sessionStorage.setItem('ref', JSON.stringify([document.referrer]));
// save referrer value -END

const oktaAuth = new OktaAuth(getConfigForEnvironment().okta);

function AppRouter() {
  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };
  return (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Switch>
        {/* Switch is here for 404 route to work */}
        <SecureRoute path="/" exact component={SessionTimeoutWarning(App)} />
        <SecureRoute path="/topusers" exact component={SessionTimeoutWarning(TopUserDashboard)} />
        <Route path="/login/callback" component={LoginCallback} />
        <Route path="/pdfFrame" render={() => <PDFFrame />} />
        <Route path="/logout" render={() => <InactivitySignout />} />
        <Route path="*" render={() => <PageNotFound />} />
      </Switch>
    </Security>
  );
}

export default AppRouter;
