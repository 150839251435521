import React, { useRef, useEffect } from 'react';

export default function CheckOutsideClick(props) {
  const ref = useRef(null);
  const { onClickOutside, children, styleClass } = props;

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      // eslint-disable-next-line no-unused-expressions
      onClickOutside && onClickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  if (!children) {
    return null;
  }

  return <div className={styleClass} ref={ref}>{children}</div>;
}
