/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AddAssistantEmailModal from './AddAssistantEmailModal';
import RemoveAssistantEmailModal from './RemoveAssistantEmailModal';
import RequestAssistantEmailModal from './RequestAssistantEmailModal';
import styles from './AssistantEmailModal.module.css';
import Modal from './Modal';
import Loader from '../../common/Loader';
import { readAssistantEmail, removeAssistantEmail } from '../../../apis/OneDDSAPI';
import { useGlobalState } from '../../../hooks/StateHook';

export default function AssistantEmailModal({ closeModal }) {
  const [addAssistantEmailmodal, setAddAssistantEmailmodal] = useState(false);
  const [removeAssistantEmailmodal, setRemoveAssistantEmailmodal] = useState(false);
  const [removeBookingEmailmodal, setRemoveBookingEmailmodal] = useState(false);
  const [requestAssistantEmailmodal, setRequestAssistantEmailmodal] = useState(false);
  const [updatingAssistants, setUpdatingAssistants] = useState(false);
  const [updatingBookingEmails, setUpdatingBookingEmails] = useState(false);
  const [assistantEmailList, setAssistantEmailList] = useState([]);
  const [bookingEmailList, setBookingEmailList] = useState([]);
  const [selectedAssistantEmails, setSelectedAssistantEmails] = useState([]);
  const [selectedBookingEmails, setSelectedBookingEmails] = useState([]);
  const [globalState] = useGlobalState();
  const { t } = useTranslation();

  const isDeleteButtonDisabled = selectedAssistantEmails.length === 0;
  const isBookingDeleteButtonDisabled = selectedBookingEmails.length === 0;

  // filter out duplicate emails
  const filterUniqueEmails = (emails) => emails && emails.filter((email, index, self) => index === self.indexOf(email));

  // const listOfBookingEmails = (bookingEmailList.body && bookingEmailList.body.map((bookingEmail) => (bookingEmail === 'No Records Available' ? '' : (bookingEmail._source.bookingEmail))));

  const listOfBookingEmails = (bookingEmailList.body && bookingEmailList.body.map((bookingEmail) => (bookingEmail === 'No Records Available'
    ? 'No Records Available' : (bookingEmail._source.bookingEmail))));

  const uniqueBookingEmails = filterUniqueEmails(listOfBookingEmails);

  console.log(uniqueBookingEmails, 'uniqueBookingEmails');

  const payloadread = {
    legend: {
      target: 'es',
      action: 'search',
    },
    body: {
      bookingEmail: globalState.userEmail,
      loggedUser: globalState.name,
      loggedUserEmail: globalState.userEmail,
    },
  };

  const payloadreadbookingemail = {
    legend: {
      target: 'es',
      action: 'search',
    },
    body: {
      assistantEmail: globalState.userEmail,
      loggedUser: globalState.name,
      loggedUserEmail: globalState.userEmail,
    },
  };

  /* istanbul ignore next */
  const handleReadAssistantEmail = () => {
    setUpdatingAssistants(true);
    readAssistantEmail(payloadread)
      .then((data) => data.json())
      .then((data) => {
        setAssistantEmailList(data);
        setUpdatingAssistants(false);
      })
      .catch((err) => {
        console.error(err);
        setUpdatingAssistants(false);
      });
  };

  /* istanbul ignore next */
  const handleReadBookingEmail = () => {
    setUpdatingBookingEmails(true);
    readAssistantEmail(payloadreadbookingemail)
      .then((data) => data.json())
      .then((data) => {
        setBookingEmailList(data);
        setUpdatingBookingEmails(false);
      })
      .catch((err) => {
        console.error(err);
        setUpdatingBookingEmails(false);
      });
  };

  /* istanbul ignore next */
  useEffect(() => {
    setTimeout(() => {
      handleReadAssistantEmail();
      handleReadBookingEmail();
    }, 1000);
  }, []);

  /* istanbul ignore next */
  useEffect(() => {
    setTimeout(() => {
      handleReadAssistantEmail();
    }, 1000);
  }, [addAssistantEmailmodal]);

  /* istanbul ignore next */
  const handleCheck = (email) => {
    if (selectedAssistantEmails.includes(email)) { // Checks if it is already selected or not
      setSelectedAssistantEmails(selectedAssistantEmails.filter((selectedAssistantEmail) => selectedAssistantEmail !== email)); // Email is selected so deselect it
    } else {
      setSelectedAssistantEmails([...selectedAssistantEmails, email]);
      // Email is not present in the array, so adds it ans selects it.
    }
  };

  /* istanbul ignore next */
  const handleBookingCheck = (email) => {
    if (selectedBookingEmails.includes(email)) {
      setSelectedBookingEmails(selectedBookingEmails.filter((selectedBookingEmail) => selectedBookingEmail !== email));
    } else {
      setSelectedBookingEmails([...selectedBookingEmails, email]);
    }
  };

  /* istanbul ignore next */
  const handleRemoveAssistantEmail = async () => {
    setUpdatingAssistants(true);
    try {
      await Promise.all(selectedAssistantEmails.map(async (email) => {
        const assistantpayloaddelete = {
          legend: {
            target: 'es',
            action: 'delete',
          },
          body: {
            bookingEmail: globalState.userEmail,
            assistantEmail: email._source.assistantEmail,
            loggedUser: globalState.name,
            loggedUserEmail: globalState.userEmail,
          },
        };
        await removeAssistantEmail(assistantpayloaddelete);
        // Update the state to remove the deleted email
        setAssistantEmailList(assistantEmailList.body.filter((item) => !selectedAssistantEmails.includes(item)));
      }));
      // handleReadAssistantEmail();
      setTimeout(() => {
        handleReadAssistantEmail();
      }, 1000);
      setSelectedAssistantEmails([]);
    } catch (err) {
      console.error(err);
      setUpdatingAssistants(false);
    }
    setUpdatingAssistants(false);
    setRemoveAssistantEmailmodal(false);
  };
  console.log(selectedBookingEmails, 'selectedBookingEmails');
  /* istanbul ignore next */
  const handleRemoveBookingEmail = async () => {
    setUpdatingBookingEmails(true);
    try {
      await Promise.all(selectedBookingEmails.map(async (email) => {
        const bookingpayloaddelete = {
          legend: {
            target: 'es',
            action: 'delete',
          },
          body: {
            bookingEmail: email,
            assistantEmail: globalState.userEmail,
            loggedUser: globalState.name,
            loggedUserEmail: globalState.userEmail,
          },
        };
        await removeAssistantEmail(bookingpayloaddelete);
        setBookingEmailList(bookingEmailList.body.filter((item) => !selectedBookingEmails.includes(item)));
      }));
      setTimeout(() => {
        handleReadBookingEmail();
      }, 1000);
      setSelectedBookingEmails([]);
    } catch (err) {
      console.error(err);
      setUpdatingBookingEmails(false);
    }
    setRemoveBookingEmailmodal(false);
    setUpdatingBookingEmails(false);
  };

  // trim email to 30 characters and add ellipses
  // const trimmer = (email) => {
  //   if (email.length > 30) {
  //     return `${email.substring(0, 30)}...`;
  //   }
  //   return email;
  // };

  return (
    <div className={styles.m2}>
      <Modal
        closeModal={closeModal}
        modalHeader={t('assistant_email_modal_header')}
      >
        <div className={styles.assistantModal}>
          <div className={assistantEmailList.body && assistantEmailList.body.length > 9 ? styles.scrollableList : ''}>
            <div className={styles.size}>
              <div className={styles.travlerGroup}>
                <div className={styles.travelerstyle}>
                  <div className={styles.boldtext}>
                    {[t('traveler'), '-']}
                  </div>
                  {t('traveler1')}
                </div>
                <div className={styles.buttongroup}>
                  <div className={styles.space}>
                    <button
                      type="button"
                      className={styles.addButton}
                      onClick={() => setAddAssistantEmailmodal(true)}
                      tabIndex={0}
                    >
                      {t('add')}
                    </button>
                  </div>
                  <div className={styles.position}>
                    <button
                      type="button"
                      className={isDeleteButtonDisabled ? styles.disabledButton : styles.addButton}
                      disabled={isDeleteButtonDisabled}
                      onClick={() => setRemoveAssistantEmailmodal(true)}
                      tabIndex={0}
                    >
                      {t('remove')}
                    </button>

                  </div>
                </div>
                <div className={styles.underline}>
                  {t('traveler2')}
                </div>
              </div>
              <div className={styles.checkboxgroup}>
                {
                updatingAssistants && updatingAssistants ? <Loader /> : assistantEmailList.body && assistantEmailList.body.map((assistantEmail) => (
                  assistantEmail === 'No Records Available' ? '' : (
                    <div className={styles.checkboxComponent}>
                      <input
                        id={assistantEmail._source.assistantEmail}
                        type="checkbox"
                        checked={selectedAssistantEmails.includes(assistantEmail)}
                        onClick={() => handleCheck(assistantEmail)}
                        className={styles.Checkbox}
                      />
                      <label
                        htmlFor="html"
                        className={styles.label}
                      >
                        {/* {assistantEmail._source.assistantEmail && trimmer(assistantEmail._source.assistantEmail)}
                        <span className={styles.tooltiptext}>{assistantEmail._source.assistantEmail}</span> */}
                        {assistantEmail._source.assistantEmail}
                      </label>
                    </div>
                  )
                ))
                }
              </div>
            </div>
          </div>

          <div className={styles.verticalLine} />
          <div className={bookingEmailList.body && bookingEmailList.body.length > 9 ? styles.scrollableList : ''}>
            <div className={styles.assistant}>
              <div className={styles.travlerGroup}>
                <div className={styles.travelerstyle}>
                  <div className={styles.boldtext}>
                    {[t('assistant'), '-']}
                  </div>
                  {t('assistant1')}
                </div>
                <div className={styles.buttongroup}>
                  <div className={styles.space}>
                    <button
                      type="button"
                      className={styles.addButton}
                      onClick={() => setRequestAssistantEmailmodal(true)}
                      tabIndex={0}
                    >
                      {t('request')}
                    </button>
                  </div>
                  <div className={styles.position}>
                    <button
                      type="button"
                      className={isBookingDeleteButtonDisabled ? styles.disabledButton : styles.addButton}
                      disabled={isBookingDeleteButtonDisabled}
                      onClick={() => setRemoveBookingEmailmodal(true)}
                      tabIndex={0}
                    >
                      {t('remove')}
                    </button>
                  </div>
                </div>
                <div className={styles.underline}>
                  {t('assistant2')}
                </div>
              </div>
              <div className={styles.checkboxgroup}>
                {
                updatingBookingEmails && updatingBookingEmails ? <Loader /> : uniqueBookingEmails && uniqueBookingEmails.map((bookingEmail) => (
                  bookingEmail === 'No Records Available' ? ''
                    : (
                      <div className={styles.checkboxComponent}>
                        <input
                          id={bookingEmail}
                          type="checkbox"
                          checked={selectedBookingEmails.includes(bookingEmail)}
                          onClick={() => handleBookingCheck(bookingEmail)}
                          className={styles.Checkbox}
                        />
                        <label
                          htmlFor="html"
                          className={styles.label}
                        >
                          {bookingEmail}
                        </label>
                      </div>
                    )
                ))
              }
              </div>
            </div>
          </div>
        </div>
        {addAssistantEmailmodal && <AddAssistantEmailModal closeModal={() => setAddAssistantEmailmodal(false)} />}
        {removeAssistantEmailmodal
          && (
          <RemoveAssistantEmailModal
            closeModal={() => setRemoveAssistantEmailmodal(false)}
            confirm={handleRemoveAssistantEmail}
            type="assistant"
          />
          )}
        {removeBookingEmailmodal
          && (
          <RemoveAssistantEmailModal
            closeModal={() => setRemoveBookingEmailmodal(false)}
            confirm={handleRemoveBookingEmail}
            type="traveler"
          />
          )}
        {requestAssistantEmailmodal && <RequestAssistantEmailModal closeModal={() => setRequestAssistantEmailmodal(false)} />}
      </Modal>
    </div>
  );
}
