import { useState, useEffect } from 'react';

const listeners = [];
let state = {
  counter: 0,
  activeBookingRef: '',
  activeInvoiceNumber: '',
  rawDocuments: [],
  selectedDocuments: [],
  tripsPageSize: 10,
  searchResultRaw: [],
  filterToggle: false,
  clearFilter: false,
  activeTab: 'trips',
  countryFilterArr: [],
  travelTypeArr: [],
  documentTypes: '',
  amountMinMaxArr: ['', ''],
  tooltipShow: true,
  emailChecks: [],
  lang: '' || '50',
  renderTab: 'invoice',
  langCode: 'en',
  reportingID: '',
  docs: [],
  search_term: '',
  search_date: [],
};

const setState = (newState) => {
  state = { ...state, ...newState };
  listeners.forEach((listener) => {
    listener(state);
  });
};

export const useGlobalState = () => {
  const newListener = useState()[1];
  useEffect(() => {
    listeners.push(newListener);
  }, []);
  return [state, setState];
};

export const readGlobalState = () => {
  const newListener = useState()[1];
  useEffect(() => {
    listeners.push(newListener);
  }, []);
  return [state];
};

export const writeGlobalState = () => {
  const newListener = useState()[1];
  useEffect(() => {
    listeners.push(newListener);
  }, []);
  return [setState];
};
