/* eslint-disable */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useGlobalState } from '../../hooks/StateHook';
import topuser from '../../images/GBT-Mktg-09-Person-Plus-RGB-1-db.svg';

export default function TopUserTab({ label }) {
  const [globalState, setGlobalState] = useGlobalState();
  return (
    <div className="Header-Tab">
      <div className="Link-line-1">
        <NavLink
          to="/topusers"
          className={({ isActive}) => (isActive ? 'active' : '')}
        >
          <img
            className="Style-Icons-Universal-Invoice-invoice_active"
            onClick={() => setGlobalState({ renderTab: 'topuser' })}
            src={topuser}
            alt="TopUser"
          />
        </NavLink>
      </div>
    </div>
  );
}
