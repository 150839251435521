import React, { useState } from 'react';

import GBTLogo from '../links/GbtLogo';

import styles from './InactivitySignout.module.css';
import Footer from '../Footer/Footer';
import NeedHelpModal from '../modal/help/NeedHelpModal';

export default function InactivitySignout() {
  const [showHelp, setShowHelp] = useState(false);

  return (
    <div>
      <div className={styles.messageContainer}>
        <div className={styles.logo}>
          <GBTLogo />
        </div>
        <img className={styles.exclaimIcon} src="https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/64x64/warning_gray04@1x.png" alt="Globe" />
        <div className={styles.mainErrorMessage}>You have been signed out due to inactivity.</div>
        <div
          onClick={() => setShowHelp(!showHelp)}
          onKeyDown={() => setShowHelp(!showHelp)}
          role="button"
          tabIndex={0}
          className={styles.needHelpText}
        >
          Need Help?
        </div>
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
      <NeedHelpModal show={showHelp} close={() => setShowHelp(false)} />
    </div>

  );
}
