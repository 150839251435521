/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import SearchElementTable from './SearchElementTable';
import styles from './QuickSearchHelpModal.module.css';
import { readGlobalState } from '../../hooks/StateHook';

export default function QuickSearchHelpModal({ closeModal }) {
  const { t } = useTranslation('locales');
  const [globalState] = readGlobalState();
  console.log(globalState.langCode, 'globalStateQ', typeof (globalState.langCode));
  return (
    <div className={styles.modal}>
      <div className={styles.m1}>
        <div
          className={styles.closeIcon}
          role="button"
          tabIndex={0}
          onClick={closeModal}
          onKeyDown={() => { console.log('Close Modal'); }}
        >
          <img className={styles.close} src="https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/16x16/close_glyphs_white@1x.png" alt="close" />
        </div>
        <div className={styles.header}>
          {t('quick_search_help')}
        </div>
        <div className={styles.container}>
          <div className={styles.body}>
            {t('quick_search_body')}
            <ol>
              <li>
                {t('quick_search1')}
                {' '}
                <br />
                <b>75.20,AUSTRIA GMBH,0150100000,TESTER</b>
              </li>
              <li>
                {t('quick_search2')}
                <SearchElementTable />
              </li>
              <li>
                {t('quick_search3')}
                <br />
                {globalState.langCode === 'hu' ? t('quick_search4a') : globalState.langCode === 'jp' ? t('quick_search4') : '*1109'}
                {' '}
                {globalState.langCode === 'it' ? t('quick_search4a') : globalState.langCode === 'jp' ? t('quick_search4jp') : t('quick_search4')}
                {' '}
                <Trans t={t} i18nKey="quick_search5" />
                {' '}
                {t('quick_search6')}
                <br />
                {globalState.langCode === 'hu' ? t('quick_search5a') : globalState.langCode === 'jp' ? '「*INCORPORATED*」' : '*INCORPORATED*'}
                {' '}
                {globalState.langCode === 'de' || globalState.langCode === 'jp' ? '' : t('quick_search4')}
                {' '}
                {globalState.langCode === 'de' ? t('quick_search7a') : globalState.langCode === 'jp' ? t('quick_search6jp') : ''}
                {' '}
                {/* <b>{t('quick_search7')}</b> */}
                {globalState.langCode === 'nl' ? ''
                  : (
                    <>
                      {' '}
                      <Trans t={t} i18nKey="quick_search7" />
                      {' '}
                    </>
                  )}
                {' '}
                {globalState.langCode === 'de' || globalState.langCode === 'hu' ? ''
                  : globalState.langCode === 'it' ? 'INCORPORATED (INCORPORATO).'
                    : globalState.langCode === 'nl' ? 'INCORPORATED'
                      : globalState.langCode === 'pl' ? '„INCORPORATED”.'
                        : globalState.langCode === 'pt' ? 'o termo INCORPORATED.'
                          : globalState.langCode === 'fi' ? 'sanan INCORPORATED.'
                            : globalState.langCode === 'jp' ? ''
                              : 'INCORPORATED.'}
                {' '}
                {globalState.langCode === 'nl'
                  ? t('quick_search7')
                  // ? (
                  //   <>
                  //     {' '}
                  //     <b><Trans t={t} i18nKey="quick_search7" /></b>
                  //     {' '}
                  //   </>
                  // )
                  : ''}
                {' '}
                {globalState.langCode === 'hu' ? t('quick_search8a') : globalState.langCode === 'jp' ? t('quick_search6') : ''}
                {' '}
                <br />
                {globalState.langCode === 'hu' ? t('quick_search8b') : globalState.langCode === 'jp' ? t('quick_search9a') : 'GBT*'}
                {' '}
                {globalState.langCode === 'jp' ? t('quick_search9b') : ''}
                {' '}
                {globalState.langCode === 'de' || globalState.langCode === 'jp' ? '' : globalState.langCode === 'fi' || globalState.langCode === 'it' ? t('quick_search4a') : t('quick_search4') }
                {' '}
                {globalState.langCode === 'de'
                  ? t('quick_search9')
                  : t('quick_search8')}
                {/* : (
                    <>
                      {' '}
                      <b><Trans t={t} i18nKey="quick_search8" /></b>
                      {' '}
                    </>
                  )} */}
                {' '}
                {globalState.langCode === 'de' ? (
                  <>
                    {' '}

                    {t('quick_search8')}
                    .

                    {' '}
                  </>
                ) : t('quick_search9')}
              </li>
              <li>
                {t('quick_search10')}
                <br />
                {t('quick_search11')}
                <br />
                {t('quick_search12')}
                {' '}
                74.51,GBT TEST CLIENT
                <br />
                <ul className={styles.list}>
                  <li>
                    {t('quick_search13')}
                    {' '}
                    {t('capsAnd')}
                    {' '}
                    {t('quick_search14')}
                    {' '}
                    {globalState.langCode === 'de'
                      ? <u>{t('reduce')}</u>
                      : (
                        <>
                          {' '}
                          {t('reduce')}
                          {' '}
                        </>
                      )}
                    {t('quick_search15')}
                    <br />
                  </li>
                </ul>
                {' '}
                {t('quick_search16')}
                {' '}
                74.51,GBT TEST CLIENT
                <br />
                <ul className={styles.list}>
                  <li>
                    {t('quick_search17')}
                    {' '}
                    {globalState.langCode === 'de' ? '' : t('capsOr')}
                    {' '}
                    {t('quick_search18')}
                    {' '}
                    {globalState.langCode === 'de'
                      ? t('quick_search19')
                      : (
                        <>
                          {t('quick_search19')}
                          {' '}
                        </>
                      )}
                    {t('quick_search20')}
                  </li>
                </ul>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}
