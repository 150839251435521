import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { getConfigForEnvironment } from '../../config/env-config';
import { writeGlobalState } from '../../hooks/StateHook';
import home from '../../images/GBT-Mktg-14-Home-RGB-1-db.svg';

import styles from './HomeTab.module.css';

export default function HomeTab() {
  const { oktaAuth } = useOktaAuth();
  const [setGlobalState] = writeGlobalState();
  const logout = async () => {
    oktaAuth.tokenManager.clear();
    // await oktaAuth.signOut();
    window.close();
    setGlobalState({ userEmail: undefined });
    const href = getConfigForEnvironment().salesforce.url;
    const link = document.createElement('a');
    link.href = href;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <div
      onClick={logout}
      onKeyDown={logout}
      role="button"
      tabIndex={0}
      className="Header-Tab"
    >
      <div className={styles.text}>
        <img
          className="Style-Icons-Universal-Home-home_active"
          alt="Home"
          src={home}
        />
      </div>
    </div>
  );
}
