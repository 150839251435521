import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './TravelType.module.css';
import { useGlobalState } from '../../../hooks/StateHook';

export default function TravelType({ values }) {

    const [list, setList] = useState([])
    const { t } = useTranslation();
    const [globalState, setGlobalState] = useGlobalState();

    const checkHandler = (e) => {
        const travelTypeCode = e.target.name
        let travelTypeSet = new Set(list)

        if (travelTypeSet.size && travelTypeSet.has(travelTypeCode)) travelTypeSet.delete(travelTypeCode)
        else travelTypeSet.add(travelTypeCode)

        setList([...travelTypeSet])
        setGlobalState({
            travelTypeArr: [...travelTypeSet],
            clearFilter: false
        })
    }

    useEffect(() => {
        setList([])
    }, [values])

    useEffect(() => {
        if (globalState.clearFilter) setList([])
    }, [globalState.clearFilter])

    return (
        <div styles={styles.travelTypeDiv}>
            <div className={styles.title}>{t('travel_type')}</div>
            {values.map((data) => (
                <div className={styles.checkboxComponent} key={data}>
                    <input
                        type="checkbox"
                        name={data}
                        className={styles.Checkbox}
                        checked={list.includes(data)}
                        onChange={checkHandler}
                    />

                    <label
                        htmlFor={data}
                        className={styles.label}
                    >
                        {data}
                    </label>
                </div>
            ))}
        </div>
    );
}

TravelType.propTypes = {
    values: PropTypes.arrayOf(PropTypes.string).isRequired
};